import { Toast } from './Toast'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeToast } from 'redux-slices/toastMessagesSlice'

export const CustomToastContainer = () => {
  const toasts = useSelector(state => state.toast.toasts)
  const dispatch = useDispatch()

  useEffect(() => {
    const timers = toasts.map(toast =>
      setTimeout(() => {
        dispatch(removeToast(toast.id))
      }, toast.options?.duration || 3000),
    )

    return () => {
      timers.forEach(timer => clearTimeout(timer))
    }
  }, [toasts, dispatch])

  return (
    <div className={`fixed top-10 right-1 space-y-2 z-50`}>
      {(toasts || []).map(toast => (
        <Toast key={toast.id} message={toast.message} type={toast.type} />
      ))}
    </div>
  )
}
