import React from 'react'
import { getFormFieldError } from 'helpers'
import { Controller } from 'react-hook-form'
import { Select, SelectItem } from '@nextui-org/react'

export const FormSelect = ({
  control = () => {},
  name = '',
  label = '',
  placeholder = '',
  items = [],
  className = '',
  disabled = false,
  errors = () => {},
}) => {
  const fieldError = getFormFieldError(errors, name)

  return (
    <div className={`flex flex-col ${className}`}>
      {disabled ? (
        <Select
          label={label}
          isDisabled={true}
          placeholder={placeholder}
          selectedKeys={new Set()}
        >
          {items.map(item => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </Select>
      ) : (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              label={label}
              isDisabled={disabled}
              placeholder={placeholder}
              selectedKeys={field.value ? new Set([field.value]) : new Set()}
              onSelectionChange={selected => {
                const selectedValue = Array.from(selected).join('')
                field.onChange(selectedValue)
              }}
            >
              {items.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </Select>
          )}
        />
      )}
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
