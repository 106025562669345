import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormTextarea } from '../form-generics/FormTextAreaField'

export const DescriptionSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-4">
      <FormTextarea
        label="Description of Damage or Loss"
        name="claim_description"
        placeholder="Provide as much information as possible regarding the theft or damage being reported"
        register={register}
        errors={errors}
        minRows={4}
        maxRows={10}
        className="col-span-2"
      />
    </div>
  )
}
