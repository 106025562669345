import React from 'react'
import { getFormFieldError } from 'helpers'
import { Controller } from 'react-hook-form'
import { Checkbox } from '@nextui-org/react'

export const FormCheckbox = ({
  control = () => {},
  name = '',
  label = '',
  className = '',
  disabled = false,
  errors = () => {},
}) => {
  const fieldError = getFormFieldError(errors, name)

  return (
    <div className={`flex flex-col ${className}`}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Checkbox
            isSelected={field.value || false}
            onChange={e => {
              field.onChange(e.target.checked)
            }}
            className="flex items-center gap-2"
            isDisabled={disabled}
          >
            <span className="text-xs text-gray-600 leading-[10px]">
              {label}
            </span>
          </Checkbox>
        )}
      />
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
