import React, { useState } from 'react'
import { useToastMessage } from 'hooks/useToastMessage'
import { updateFormColor } from 'services/adminService'
import { CustomLoader } from 'components/generics/loader'
import { Button, Card, CardBody, Tooltip } from '@nextui-org/react'
import { EditClaimFormTemplate } from 'components/admin/dashboard/edit-claim-template'
import { ColorPickerPanel } from 'components/admin/dashboard/edit-claim-template/colour-panel'

export const EditClaimTemplatePage = () => {
  const { showToast } = useToastMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [templateKey, setTemplateKey] = useState(null)
  const [formBgColor, setFormBgColor] = useState('#FFFFFF')
  const [isColorChanged, setIsColorChanged] = useState(false)

  const handleColorSelection = color => {
    if (color !== formBgColor) {
      setFormBgColor(color)
      setIsColorChanged(true)
    }
  }

  const handleFormColor = async () => {
    setIsLoading(true)
    try {
      const response = await updateFormColor(templateKey, formBgColor)
      if (response.status === 200) {
        showToast('Form color updated successfully', 'success')
        setIsColorChanged(false)
      }
    } catch (error) {
      showToast('Error updating form color', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col h-full w-full bg-grey-50 lg:bg-gray-50">
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <CustomLoader />
        </div>
      )}

      <div className="flex py-6 items-center justify-center">
        <div className="flex gap-3">
          <div className="flex flex-col items-start gap-3">
            <div className="flex gap-3 items-center justify-between">
              <span className="text-base text-gray-500">Change Form Color</span>
              <Tooltip content="Selected form color will be visible to users">
                <Button
                  className="bg-gold-100 text-xs cursor-pointer"
                  size="sm"
                  radius="full"
                  onPress={handleFormColor}
                  isDisabled={isLoading || !templateKey || !isColorChanged}
                >
                  Save
                </Button>
              </Tooltip>
            </div>
            <ColorPickerPanel onColorSelect={handleColorSelection} />
          </div>
          <div className="w-full max-w-4xl px-6 lg:px-24">
            <Card style={{ backgroundColor: formBgColor }}>
              <CardBody>
                <div className="flex flex-col md:flex-row items-center sm:mt-0">
                  <div className="flex justify-center w-full">
                    <EditClaimFormTemplate
                      setTemplateKey={setTemplateKey}
                      setFormBgColor={setFormBgColor}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
