import React from 'react'
import { useLocation } from 'react-router-dom'
import { AdminSideBar } from 'components/admin/sidebar'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'
import { AdminDashboard } from 'components/admin/dashboard/AdminDashboard'

export const AdminDashboardPage = () => {
  const pathname = useLocation()
  const pathnameVals = pathname.pathname.split('/')
  return (
    <div className="flex flex-col h-dvh w-full bg-white lg:bg-gray-50">
      <div>
        <AppNavBar />
      </div>
      <div className="flex py-6 w-full">
        <div>
          {!pathnameVals.includes('edit-claim-template') && <AdminSideBar />}
        </div>
        <div className="w-full px-8">
          <AdminDashboard />
        </div>
      </div>
    </div>
  )
}
