import { formatAndCapitalize } from 'helpers'

export const formatMailingAddress = mailingAddress => {
  if (!mailingAddress || Object.keys(mailingAddress).length === 0) {
    return 'N/A'
  }
  return Object.entries(mailingAddress)
    .map(([key, value]) => `${formatAndCapitalize(key)}: ${value}`)
    .join(', ')
}

export const addressFieldsNames = {
  MAILING_ADDRESS: 'mailing_address',
  PARKING_ADDRESS: 'parking_address',
}

export const formatAddressFields = (data = {}, addressType = '') => {
  if (!data || typeof data !== 'object' || !addressType) {
    return ''
  }

  const addressParts = addressType.split('_')
  const addressFields = {
    info: `${addressType}_info`,
    city: `${addressParts[0]}_city`,
    state: `${addressParts[0]}_state`,
    zipCode: `${addressParts[0]}_zip_code`,
  }

  const formattedAddressParts = []

  if (data[addressFields.info]) {
    formattedAddressParts.push(`Address: ${data[addressFields.info]}`)
  }
  if (data[addressFields.city]) {
    formattedAddressParts.push(`City: ${data[addressFields.city]}`)
  }
  if (data[addressFields.state]) {
    formattedAddressParts.push(`State: ${data[addressFields.state]}`)
  }
  if (data[addressFields.zipCode]) {
    const zipCodes = data[addressFields.zipCode]
      .split(' ')
      .map(zip => zip.trim())
      .filter(zip => zip)
    formattedAddressParts.push(`ZipCode: ${zipCodes.join(', ')}`)
  }

  return formattedAddressParts.join(', ')
}
