import React from 'react'
import { Pagination } from '@nextui-org/react'

export const PaginationComponent = ({
  total = 4,
  initialPage = 1,
  page,
  onChange,
  variant = 'light',
  color = 'primary',
  size = 'md',
  radius = 'lg',
  isCompact = true,
  showControls = true,
  classNames = {},
  renderItem,
  ...rest
}) => {
  return (
    <Pagination
      total={total}
      initialPage={initialPage}
      page={page}
      onChange={onChange}
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      isCompact={isCompact}
      showControls={showControls}
      classNames={classNames}
      renderItem={renderItem}
      {...rest}
    />
  )
}
