export const AuthEndPoints = {
  login: '/api/auth/login/',
  createUser: '/api/auth/register/',
  getUserDetails: '/api/auth/user/',
  generateQRCode: '/api/auth/2fa/generate_qr_code/',
  verifyOTP: '/api/auth/2fa/verify_otp/',
}

export const PartnerEndPoints = {
  submitPartnerClaimForm: '/api/partner/form-submission/',
  updatePartnerOfferDetails: '/api/partner/update-offer/',
  getClaimDataByIds: '/api/partner/claims-by-ids/',
  getClientsById: '/api/partner/clients-by-ids/',
  createAllClaimsDownload: '/api/partner/generate-claim-report/',
  createAllClientDownload: '/api/partner/generate-customer-report/',
  uploadReport: '/api/partner/upload-report/',
  generateBatchPresignedUrls: '/api/partner/generate-presigned-urls/',
  getAllClaimSubmissions: (page, q, filter, date_start, date_end) =>
    `/api/partner/claims/?page=${page}&q=${q}&filter=${filter}&date_start=${date_start}&date_end=${date_end}`,
  getAllPartnerCustomers: (page, q, f, date_start, date_end) =>
    `/api/partner/clients/?page=${page}&q=${q}&date_start=${date_start}&date_end=${date_end}`,
  getPartnerFormTemplates: (page, q) =>
    `api/partner/forms/?page=${page}&q=${q}`,
  verifyClaimTemplate: url_suffix =>
    `api/partner/verify-template/${url_suffix}`,
  getClaimFormOfClient: claim_form_id =>
    `/api/partner/claims/${claim_form_id}/`,
  getAllDownloadReports: (page, q, f, date_start, date_end) => {
    const url = `/api/partner/reports/?page=${page}`
    const params = []

    if (f) params.push(`type=${f}`)
    if (date_start) params.push(`date_start=${date_start}`)
    if (date_end) params.push(`date_end=${date_end}`)

    return `${url}&${params.join('&')}`
  },
  getAllMonthlyReports: (page, q, f, date_start, date_end) => {
    const url = `/api/partner/uploaded-reports/?page=${page}`
    const params = []

    if (f) params.push(`type=${f}`)
    if (date_start) params.push(`date_start=${date_start}`)
    if (date_end) params.push(`date_end=${date_end}`)

    return `${url}&${params.join('&')}`
  },
}

export const CustomerEndPoints = {
  getCustomerDetails: id => `/api/partner/clients/${id}`,
}

export const AdminEndPoints = {
  addNewFormField: access_key =>
    `/api/admin/templates/${access_key}/fields/add/`,
  deleteFormField: field_id => `/api/admin/fields/${field_id}/delete/`,
  getPartnerClaimTemplates: (page, partnerId) => {
    const url = `/api/admin/form-templates?page=${page}`
    const params = []

    if (partnerId) params.push(`partner=${partnerId}`)

    return `${url}&${params.join('&')}`
  },
  updateFormField: field_id => `/api/admin/fields/${field_id}/update/`,
  updateFormColor: access_key => `/api/admin/templates/${access_key}/update/`,
}
