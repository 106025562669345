import Papa from 'papaparse'

export const partnerTabActionsConst = {
  Claims: 'Claims',
  Customers: 'Customers',
}

const filterColumns = (data, columnsToExclude) => {
  return data.map(item =>
    Object.fromEntries(
      Object.entries(item).filter(([key]) => !columnsToExclude.includes(key)),
    ),
  )
}

const expandAdditionalFields = (data, partnersTabAction) => {
  const additionalField =
    partnersTabAction === partnerTabActionsConst.Customers
      ? 'additional_info'
      : 'additional_fields_responses'

  return data.map(item => {
    const expandedItem = { ...item }
    if (item[additionalField]) {
      Object.entries(item[additionalField]).forEach(([key, value]) => {
        expandedItem[key] = value
      })
      delete expandedItem[additionalField]
    }
    return expandedItem
  })
}

export const generateCSV = (
  data,
  columnsToExclude = [],
  partnersTabAction = partnerTabActionsConst.Claims,
) => {
  const expandedData = expandAdditionalFields(data, partnersTabAction)
  const filteredData = filterColumns(expandedData, columnsToExclude)
  return Papa.unparse(filteredData, { header: true })
}

export const downloadCSV = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
