import React from 'react'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'
import { PartnerSideBar } from 'components/partner/sidebar/app-sidebar'
import { PartnerDashboard } from 'components/partner/dashboard/PartnerDashboard'

export const PartnerDashboardPage = () => {
  return (
    <div className="flex flex-col h-dvh w-full bg-white lg:bg-gray-50">
      <div>
        <AppNavBar />
      </div>
      <div className="flex py-6 w-full">
        <div>
          <PartnerSideBar />
        </div>
        <div className="w-full px-8 overflow-hidden">
          <PartnerDashboard />
        </div>
      </div>
    </div>
  )
}
