import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  toasts: [],
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action) => {
      const { id, message, type = 'info', options = {} } = action.payload
      state.toasts.push({ id, message, type, options })
    },
    removeToast: (state, action) => {
      const id = action.payload
      state.toasts = state.toasts.filter(toast => toast.id !== id)
    },
    clearToast: state => {
      state.toasts = []
    },
  },
})

// Dispatching the removeToast action after the delay
export const removeToastAfterDelay = id => dispatch => {
  setTimeout(() => {
    dispatch(removeToast(id))
  }, 3000)
}

export const { addToast, removeToast, clearToast } = toastSlice.actions
export const toastReducer = toastSlice.reducer
