import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormCheckbox } from '../form-generics/FormCheckboxField'
import {
  claimFormCheckboxOptions,
  claimFormUserInstructions,
} from '../form-constants'

export const TermsSection = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <div className="grid grid-cols-1 gap-6 py-4">
      {/* Legal Form Clause */}
      <FormCheckbox
        control={control}
        errors={errors}
        name="legalFormClause"
        label={claimFormCheckboxOptions.legalFormClause}
        className="col-span-2 text-justify"
      />

      {/* Terms Clause */}
      <FormCheckbox
        control={control}
        errors={errors}
        name="termsClause"
        label={claimFormCheckboxOptions.termsAndConditions}
        className="col-span-2 text-justify"
      />

      {/* User Instructions */}
      <span className="text-xs text-gray-500 col-span-2 px-3 text-justify">
        {claimFormUserInstructions}
      </span>
    </div>
  )
}
