import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  addToast,
  removeToast,
  clearToast,
} from 'redux-slices/toastMessagesSlice'

export const useToastMessage = () => {
  const dispatch = useDispatch()
  const { toasts } = useSelector(state => state.toast)

  useEffect(() => {
    toasts.forEach(toastItem => {
      toast(toastItem.message, {
        type: toastItem.type,
        autoClose: toastItem.duration || 3000,
        ...toastItem.options,
        onClose: () => dispatch(removeToast(toastItem.id)),
      })
    })
  }, [toasts, dispatch])

  const showToast = (message, type = 'default', options = {}) => {
    const id = uuidv4()
    const duration = options.duration || 3000
    dispatch(addToast({ id, message, type, options, duration }))
  }

  const clearAllToasts = () => {
    dispatch(clearToast())
  }

  return { showToast, clearAllToasts }
}
