import React from 'react'
import { Info, XCircle, Warning, CheckCircle } from '@phosphor-icons/react'

export const ToastMessageIcon = ({ type }) => {
  const toastTypes = {
    success: {
      icon: <CheckCircle size={24} />,
      toastColor: 'green',
    },
    error: {
      icon: <XCircle size={24} />,
      toastColor: 'red',
    },
    warning: {
      icon: <Warning size={24} />,
      toastColor: 'orange',
    },
    info: {
      icon: <Info size={24} />,
      toastColor: 'blue',
    },
    generic: {
      icon: <Info size={24} />,
      toastColor: 'gray',
    },
  }

  const { icon, toastColor } = toastTypes[type] || toastTypes.generic

  return (
    <div
      className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-${toastColor}-100 text-${toastColor}-600`}
    >
      {icon}
    </div>
  )
}
