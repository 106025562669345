import { uploadReport } from 'services/partnerService'

export const formatDateRange = dateRange => {
  if (!Array.isArray(dateRange) || dateRange.length !== 2) {
    throw new Error(
      'Invalid date range format. Expected an array with two dates.',
    )
  }

  const [start, end] = dateRange.map(date => {
    if (date === null) return null
    const parsedDate = new Date(date)
    return parsedDate.toISOString().split('T')[0]
  })

  return {
    date_start: start,
    date_end: end,
  }
}

export const handleUploadCsv = async (
  type,
  csvFile,
  setIsUploading,
  handleSearch,
  showToastCallback,
) => {
  try {
    setIsUploading(true)
    const response = await uploadReport(csvFile, type)
    if (response.status === 201) {
      handleSearch('')
      showToastCallback('CSV uploaded successfully!', 'success')
    }
  } catch (error) {
    showToastCallback('Something went wrong!', 'error')
  } finally {
    setIsUploading(false)
  }
}
