export const getFileUploadErrorMessages = error => {
  if (!error) return []

  if (error.message) {
    return [error.message]
  }

  if (Array.isArray(error)) {
    return error.map(err => err?.message || 'Invalid file')
  }

  if (typeof error === 'object') {
    return Object.values(error).flatMap(err => {
      if (err?.message) return [err.message]
      if (Array.isArray(err)) {
        return err.map(e => e?.message || 'Invalid file')
      }
      return []
    })
  }

  return ['Invalid file']
}
