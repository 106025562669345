import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { toastReducer } from 'redux-slices/toastMessagesSlice'
import { currentUserReducer } from 'redux-slices/currentUserSlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['toast'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    currentUser: currentUserReducer,
    toast: toastReducer,
  }),
)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
