import React from 'react'
import { Button } from '@nextui-org/react'
import { Pencil, TrashSimple } from '@phosphor-icons/react'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'
import { FormSelect } from 'components/partner/claim-forms/form-generics/FormSelectField'

export const CustomisedFieldDisplay = ({
  field,
  onEdit,
  onDelete,
  control,
}) => {
  const renderEditDeleteButtons = () => (
    <div className="flex gap-2">
      <Button
        size="sm"
        onPress={() => onEdit(field)}
        className="bg-transparent border-none w-1 h-5 mt-0.5 p-0 justify-start min-w-[15px] max-w-[15px]"
      >
        <Pencil size={15} />
      </Button>
      <Button
        size="sm"
        onPress={() => onDelete(field.id)}
        className="bg-transparent border-none w-1 h-5 mt-0.5 justify-start p-0 min-w-[15px] max-w-[15px]"
      >
        <TrashSimple size={15} />
      </Button>
    </div>
  )

  return (
    <>
      {(field.type === 'text' || field.type === 'email') && (
        <div>
          <FormInput
            label={field.name}
            name={field.name}
            type={field.type}
            register={() => {}}
            errors={{}}
          />
          {renderEditDeleteButtons()}
        </div>
      )}
      {(field.type === 'dropdown' || field.type === 'choice') && (
        <div className="p-0.25">
          <FormSelect
            control={control}
            name={field.name}
            label={field.name}
            items={field.options.map(option => ({
              value: option.name,
              label: option.name,
            }))}
            errors={{}}
          />
          {renderEditDeleteButtons()}
        </div>
      )}
    </>
  )
}
