export const defaultColumnsToDisplay = [
  'id',
  'first_name',
  'email',
  'phone_number',
  'car_information',
  'status',
  'claim_amount_paid',
  'created_at',
  'actions',
]

export const allColumns = [
  'id',
  'status',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'parking_start_date',
  'parking_end_date',
  'claim_amount_paid',
  'pg_claim_number',
  'car_information',
  'mailing_address_info',
  'mailing_city',
  'mailing_state',
  'mailing_zip_code',
  'parking_address_info',
  'parking_city',
  'parking_state',
  'parking_zip_code',
  'license_plate_info',
  'license_plate_state',
  'type_of_damage',
  'damage_discovery_date',
  'created_at',
]

export const dateFields = [
  'parking_start_date',
  'parking_end_date',
  'damage_discovery_date',
  'created_at',
]

export const filterSubmissionOptions = [
  {
    value: 'in_review',
    label: 'In Review',
  },
  {
    value: 'declined',
    label: 'Declined',
  },
  {
    value: 'accepted_and_paid',
    label: 'Accepted',
  },
]

export const columnsToExcludeFromCsv = ['id', 'updated_at']

export const downloadCSVFilename = 'ClaimForm_Submission_Data.csv'

export const requestSubmissionModalWarning = {
  title: '⚠︎ Attention',
  body: 'If you want to download all existing Claim Data, please remove any applied filters. If a filter is applied, only the filtered data will be downloaded.',
}

export const claimsWarningModalActions = onDownloadPress => [
  {
    label: 'Go Back',
    color: 'danger',
    variant: 'solid',
    button_background: 'bg-gold-100',
  },
  {
    label: 'Download',
    color: 'primary',
    variant: 'solid',
    button_background: 'bg-gray-950',
    onPress: onDownloadPress,
  },
]

export const requestSubmissionModalText = {
  title: '✅ Request Submitted',
  body: 'Your request to download the claim submissions has been submitted. Kindly visit the Report tab to check the progress of your report download',
}
export const claimsTabModalActions = onReportTabPress => [
  { label: 'Close', color: 'danger', variant: 'light' },
  {
    label: 'Report tab',
    color: 'default',
    variant: 'light',
    button_background: 'bg-gold-100',
    onPress: onReportTabPress,
  },
]
