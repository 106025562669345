import { useSelector } from 'react-redux'
import { copyToClipboard } from 'helpers'
import { formatDataForTable } from 'helpers'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { getFormTemplates } from 'services/partnerService'
import { columnsToDisplay } from './claim-form-tab-constants'
import React, { useCallback, useMemo, useState } from 'react'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { ClipboardCopyIcon, EyeIcon } from '@heroicons/react/outline'
import { DefaultTableComponent } from 'components/generics/DefaultTableComponent'
import { SearchBarComponent } from 'components/generics/search-bar/SearchBarComponent'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'

export const ClaimFormsViewTab = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [searchValue, setSearchValue] = useState('')

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleSearch,
  } = usePagination(getFormTemplates)

  const tableData = useMemo(
    () => formatDataForTable(data, columnsToDisplay),
    [data],
  )

  const renderActions = useCallback(
    form => {
      const url = `/${form?.url_suffix}`
      return (
        <div className="flex gap-3 justify-center">
          <Tooltip content="View">
            <span className="cursor-pointer">
              <a href={url} target="_blank" rel="noopener noreferrer">
                <EyeIcon className="h-4 w-4" />
              </a>
            </span>
          </Tooltip>
          <Tooltip content="Copy">
            <span
              className="cursor-pointer"
              onClick={() => copyToClipboard('', url)}
            >
              <ClipboardCopyIcon className="h-4 w-4" />
            </span>
          </Tooltip>
        </div>
      )
    },
    [currentUser],
  )

  const onSearchInputChange = value => setSearchValue(value)

  const onSearchExecute = (refetch = false) => {
    refetch ? handleSearch('') : handleSearch(searchValue)
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <h2 className="text-2xl text-gray-600">Claim Form Templates</h2>
            <SearchBarComponent
              value={searchValue}
              onChange={onSearchInputChange}
              onSearch={onSearchExecute}
            />
          </div>
          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActions}
          />
        </div>
      )}
      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </div>
  )
}
