import { AddCustomField } from './AddCustomField'
import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react'

export const AddFieldModal = ({
  isOpen,
  onClose,
  onSave = () => {},
  initialData,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    type: 'text',
    options: [],
  })

  useEffect(() => {
    if (initialData) {
      const options = initialData.options?.map(option => option.name) || []
      setFormData({
        name: initialData.name || '',
        type: initialData.type || 'text',
        options,
      })
    } else {
      setFormData({
        name: '',
        type: 'text',
        options: [],
      })
    }
  }, [initialData, isOpen])

  const handleSave = () => {
    onSave(formData)
  }

  return (
    <Modal
      backdrop="opaque"
      isOpen={isOpen}
      onOpenChange={onClose}
      classNames={{
        backdrop:
          'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20',
        modal: 'max-w-lg mx-auto',
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          Add | Update Custom Field
        </ModalHeader>
        <ModalBody>
          <AddCustomField
            formData={
              initialData
                ? {
                    ...initialData,
                    options: initialData.options.map(option => option.name),
                  }
                : formData
            }
            setFormData={setFormData}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="default" onPress={onClose}>
            Close
          </Button>
          <Button className="bg-gold-100" onPress={handleSave}>
            {initialData ? 'Update' : 'Add'} Field
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
