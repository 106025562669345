import React, { useState } from 'react'
import { ColorPicker } from 'react-pick-color'
import { presetColourOptions } from './colour-panel-constant'

export const ColorPickerPanel = ({ onColorSelect }) => {
  const [color, setColor] = useState('#FFFFFF')

  const handleColorChange = newColor => {
    setColor(newColor.hex)
    if (onColorSelect) {
      onColorSelect(newColor.hex)
    }
  }

  return (
    <div>
      <ColorPicker
        color={color}
        onChange={handleColorChange}
        presets={presetColourOptions}
      />
    </div>
  )
}
