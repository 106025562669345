import React, { useState } from 'react'
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from '@nextui-org/react'
import { Plus } from '@phosphor-icons/react'

export const MultiSelectTableDropdown = ({
  allOptions = [],
  defaultSelectedOptions = [],
  hideColumns = ['id'],
  onSelectionChange,
  variant = 'light',
  selectedLabel = 'Change Column View',
  buttonClassName = '',
  iconClassName = 'gap-0',
}) => {
  const [selectedKeys, setSelectedKeys] = useState(
    new Set(defaultSelectedOptions),
  )

  const handleSelectionChange = keys => {
    setSelectedKeys(keys)
    if (onSelectionChange) {
      onSelectionChange(Array.from(keys))
    }
  }

  const visibleOptions = allOptions.filter(
    option => !hideColumns.includes(option),
  )

  return (
    <Dropdown
      className="relative"
      PopoverProps={{
        placement: 'bottom',
        offset: 10,
        containerClassName: 'z-50',
      }}
    >
      <DropdownTrigger>
        <Button
          variant={variant}
          className={`flex items-center px-5 py-2 border rounded-xl shadow-sm focus:outline-none hover:bg-gray-100 min-w-40 min-h-10 ${buttonClassName}`}
        >
          <Plus className={`text-base ${iconClassName}`} />
          <span className="text-sm text-gray-600">{selectedLabel}</span>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Column Selection"
        variant="flat"
        closeOnSelect={false}
        disallowEmptySelection
        selectionMode="multiple"
        selectedKeys={selectedKeys}
        onSelectionChange={handleSelectionChange}
        className="overflow-y-auto max-h-80 z-50"
      >
        {visibleOptions.map(option => (
          <DropdownItem key={option}>{option.replace(/_/g, ' ')}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
