import React from 'react'
import { getFormFieldError } from 'helpers'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@nextui-org/react'
import { parseDate } from '@internationalized/date'

export const FormDatePicker = ({
  control,
  name = '',
  label = '',
  errors = {},
  className = '',
  disabled = false,
  ...rest
}) => {
  const fieldError = getFormFieldError(errors, name)

  return (
    <div className={`flex flex-col ${className}`}>
      {disabled ? (
        <DatePicker
          label={label}
          isDisabled={true}
          value={null}
          className="w-full"
          {...rest}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            const { value, onChange } = field
            const dateValue = value ? parseDate(value) : null

            return (
              <DatePicker
                {...field}
                label={label}
                value={dateValue}
                isDisabled={disabled}
                onChange={date => {
                  const isoString = date?.toString()
                  onChange(isoString)
                }}
                className="w-full"
                {...rest}
              />
            )
          }}
        />
      )}
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
