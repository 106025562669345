import React from 'react'
import badgeImage from 'assets/gifs/badge.gif'

export const ClaimSubmissionThankYou = () => {
  return (
    <div className="flex flex-col h-screen w-full bg-white lg:bg-gray-50">
      <div className="flex-grow flex flex-col items-center justify-center px-4 md:px-20">
        <img
          src={badgeImage}
          alt="Badge"
          className="w-24 h-24 md:w-32 md:h-32 mb-6 bg-white mx-auto"
        />

        <h1 className="text-3xl md:text-4xl font-bold text-center text-black mb-4">
          Thank you!
        </h1>

        <p className="text-lg md:text-xl font-bold text-center text-gray-600">
          Your claim has been submitted. Our claims team will be in contact with
          next steps.
        </p>
      </div>
    </div>
  )
}
