import * as Yup from 'yup'
import { useAuth } from 'hooks/useAuth'
import { QRCodeSVG } from 'qrcode.react'
import { useForm } from 'react-hook-form'
import React, { useState, useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomLoader } from 'components/generics/loader'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { Input, Button, Card, Spacer } from '@nextui-org/react'
import { loginValidationSchema } from 'validations/authenticationSchema'

export const LoginForm = () => {
  const {
    initialValues,
    isLoading,
    handleSubmit: onAuthSubmit,
    handleOTPVerification,
    requires2FASetup,
    requires2FAVerification,
    qrCodeUrl,
  } = useAuth()

  const [otpCode, setOtpCode] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const requiresOTP = requires2FASetup || requires2FAVerification
  const dynamicLoginSchema = requiresOTP
    ? loginValidationSchema.shape({
        otpCode: Yup.string().required('OTP is required'),
      })
    : loginValidationSchema

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(prevState => !prevState)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(dynamicLoginSchema),
    defaultValues: {
      username: initialValues.username,
      password: initialValues.password,
      otpCode: '',
    },
    mode: 'onChange',
  })

  const onSubmit = loginData => {
    onAuthSubmit({ ...loginData, username: loginData.username.toLowerCase() })
  }

  const onOTPSubmit = async e => {
    e.preventDefault()
    if (otpCode) {
      await handleOTPVerification(otpCode)
    } else {
      setValue('otpCode', otpCode, { shouldValidate: true })
    }
  }

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <CustomLoader />
        </div>
      )}
      <Card className="w-full p-5 max-w-[400px] min-h-[350px] justify-center">
        {!requires2FASetup && !requires2FAVerification && (
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <h3 className="text-center text-2xl font-bold mb-6">Login</h3>

            <div className="mb-4">
              <Input
                label="Username"
                type="text"
                placeholder="Enter your username"
                {...register('username')}
                variant="bordered"
                fullWidth
              />
              {errors.username && (
                <p className="text-red-500 text-sm">
                  {errors.username.message}
                </p>
              )}
            </div>

            <Spacer y={0.5} />

            <div className="mb-4 relative">
              <Input
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                {...register('password')}
                variant="bordered"
                fullWidth
              />
              <div
                className="absolute top-[20px] right-2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <EyeOffIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-500" />
                )}
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
            </div>

            <Spacer y={1.5} x={1.5} />

            <Button
              type="submit"
              fullWidth
              color="primary"
              isDisabled={!isValid}
            >
              Login
            </Button>
          </form>
        )}

        {requires2FASetup && (
          <div className="flex flex-col items-center">
            <h3 className="text-center text-2xl font-bold mb-6">Set up 2FA</h3>
            <p>Scan the QR code below with your authenticator app:</p>
            {qrCodeUrl && (
              <QRCodeSVG value={qrCodeUrl} size={200} className="my-4" />
            )}
            <form onSubmit={onOTPSubmit} className="w-full">
              <Input
                label="Enter OTP"
                type="text"
                placeholder="Enter the OTP"
                {...register('otpCode')}
                onChange={e => setOtpCode(e.target.value)}
                variant="bordered"
                fullWidth
              />
              {errors.otpCode && (
                <p className="text-red-500 text-sm">{errors.otpCode.message}</p>
              )}
              <Button type="submit" fullWidth color="primary" className="mt-4">
                Verify OTP
              </Button>
            </form>
          </div>
        )}

        {requires2FAVerification && (
          <div>
            <h3 className="text-center text-2xl font-bold mb-6">Verify OTP</h3>
            <form onSubmit={onOTPSubmit} className="w-full">
              <Input
                label="Enter OTP"
                type="text"
                placeholder="Enter the OTP from your authenticator app"
                {...register('otpCode')}
                onChange={e => setOtpCode(e.target.value)}
                variant="bordered"
                fullWidth
              />
              {errors.otpCode && (
                <p className="text-red-500 text-sm">{errors.otpCode.message}</p>
              )}
              <Button type="submit" fullWidth color="primary" className="mt-4">
                Verify OTP
              </Button>
            </form>
          </div>
        )}
      </Card>
    </>
  )
}
