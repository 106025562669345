import React from 'react'
import { Input } from '@nextui-org/react'
import { MagnifyingGlass } from '@phosphor-icons/react'

export const SearchBarComponent = ({
  value,
  onChange,
  onSearch,
  label,
  size = 'sm',
  radius = 'md',
  classNames = {},
  inputWrapperClass = '',
  inputClass = 'bg-gray-500',
  placeholder = 'Type to search...',
  isClearable = true,
}) => {
  const handleInputChange = e => {
    onChange(e.target.value)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  const handleClear = () => {
    onChange('')
    onSearch({ refetch: true })
  }

  return (
    <div className={`relative ${inputWrapperClass}`}>
      <Input
        type="text"
        label={label}
        value={value}
        onBlur={() => onSearch()}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onClear={handleClear}
        isClearable={isClearable}
        radius={radius}
        size={size}
        classNames={{
          input: `text-sm bg-transparent text-black dark:text-white placeholder:text-gray-500/60 ${inputClass}`,
          inputWrapper: 'min-h-10 min-w-56 bg-white dark:bg-gray-800 shadow-sm',
          ...classNames,
        }}
        placeholder={placeholder}
        startContent={
          <MagnifyingGlass className="text-gray-600 dark:text-gray-200 pointer-events-none" />
        }
      />
    </div>
  )
}
