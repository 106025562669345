import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearCurrentUser } from 'redux-slices/currentUserSlice'
import parkguardLogo from 'assets/images/park-guard-simple-logo.png'
import { LoginForm } from 'components/authentication/login/LoginForm'

export const HomePage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clearCurrentUser())
  }, [dispatch])
  return (
    <div className="min-h-screen bg-white text-gray-800 flex items-center justify-center p-4">
      <div className="w-full max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center items-start text-center md:text-left">
          <div className="flex justify-center w-full md:justify-start mb-6">
            <img
              src={parkguardLogo}
              alt="ParkGuard Logo"
              className="h-24 w-24 object-contain rounded-full shadow-lg"
            />
          </div>

          <h1 className="text-4xl md:text-6xl font-extrabold tracking-wide text-gray-900">
            Welcome to Park Guard Coverage Hub
          </h1>

          <p className="mt-4 text-lg md:text-xl text-gray-600">
            Your gateway to a seamless protection plan experience
          </p>

          <div className="flex flex-col item-center w-full">
            <div className="mt-10 flex justify-center md:justify-start gap-6">
              <div className="w-3 h-3 rounded-full bg-blue-500 animate-pulse"></div>
              <div className="w-3 h-3 rounded-full bg-blue-500 animate-pulse delay-200"></div>
              <div className="w-3 h-3 rounded-full bg-blue-500 animate-pulse delay-400"></div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <LoginForm />
        </div>
      </div>
    </div>
  )
}
