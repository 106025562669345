import React from 'react'
import { InfoCard } from './InfoCard'
import { Chip } from '@nextui-org/react'
import { formatAndCapitalize } from 'helpers'
import {
  addressFieldsNames,
  formatAddressFields,
  formatMailingAddress,
} from './claim-view-helper/claim-view-helper'

export const renderClaimInfo = (claimData = {}) => (
  <InfoCard title="Claim Information">
    {[
      {
        label: 'Status',
        value: <Chip>{formatAndCapitalize(claimData?.status) ?? 'N/A'}</Chip>,
      },
      { label: 'First Name', value: claimData?.first_name ?? 'N/A' },
      { label: 'Last Name', value: claimData?.last_name ?? 'N/A' },
      { label: 'Email', value: claimData?.email ?? 'N/A' },
      { label: 'Phone Number', value: claimData?.phone_number ?? 'N/A' },
      {
        label: 'Mailing Address',
        value: claimData?.mailing_address_info
          ? formatAddressFields(claimData, addressFieldsNames.MAILING_ADDRESS)
          : claimData?.mailing_address
            ? formatMailingAddress(claimData.mailing_address)
            : 'N/A',
      },
      {
        label: 'Parking Facility Address',
        value: claimData?.parking_address_info
          ? formatAddressFields(claimData, addressFieldsNames.PARKING_ADDRESS)
          : claimData?.parking_facility_address
            ? `${claimData.parking_facility_address}`
            : 'N/A',
      },
    ].map(({ label, value }, index) => (
      <div key={index}>
        <strong className="text-gray-500 text-sm">{label}:</strong> {value}
      </div>
    ))}
  </InfoCard>
)

export const renderCarDamageInfo = (claimData = {}) => (
  <InfoCard title="Car and Damage Information">
    {[
      { label: 'Car Information', value: claimData?.car_information ?? 'N/A' },
      {
        label: 'License Plate Info',
        value: `${claimData?.license_plate_info ?? 'N/A'} (${claimData?.license_plate_state || 'N/A'})`,
      },
      {
        label: 'Type of Damage',
        value: formatAndCapitalize(claimData?.type_of_damage) ?? 'N/A',
      },
      {
        label: 'Damage Discovery Date',
        value: (
          <Chip radius="sm" className="bg-gold-100">
            {claimData?.damage_discovery_date
              ? new Date(claimData?.damage_discovery_date).toLocaleDateString()
              : 'N/A'}
          </Chip>
        ),
      },
      {
        label: 'Parking Dates',
        value: (
          <>
            <Chip radius="sm" className="bg-gold-100">
              {claimData?.parking_start_date
                ? new Date(claimData?.parking_start_date).toLocaleDateString()
                : 'N/A'}
            </Chip>{' '}
            to{' '}
            <Chip radius="sm" className="bg-gold-100">
              {claimData?.parking_end_date
                ? new Date(claimData?.parking_end_date).toLocaleDateString()
                : 'N/A'}
            </Chip>
          </>
        ),
      },
      {
        label: 'Claim Submitted',
        value: (
          <Chip radius="sm" color="default">
            {claimData?.created_at
              ? new Date(claimData?.created_at).toLocaleDateString()
              : 'N/A'}
          </Chip>
        ),
      },
      {
        label: 'Claim Amount Paid',
        value: (
          <Chip radius="sm" color="default" className="mt-2">
            {claimData?.claim_amount_paid
              ? claimData?.claim_amount_paid
              : 'Not Paid'}
          </Chip>
        ),
      },
    ].map(({ label, value }, index) => (
      <div key={index} className={label.includes('Date') ? 'py-1' : ''}>
        <strong className="text-gray-500 text-sm">{label}:</strong> {value}
      </div>
    ))}
  </InfoCard>
)

export const renderAdditionalFields = (claimData = {}) => {
  const additionalFields = claimData?.additional_fields_responses || {}

  return (
    <InfoCard title="Further Details">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {Object.entries(additionalFields).map(([key, value], index) => (
          <div key={index} className="flex flex-col">
            <span>
              <strong className="text-gray-500 text-sm">
                {formatAndCapitalize(key.replace(/_/g, ' '))}:
              </strong>{' '}
              {value ?? 'N/A'}
            </span>
          </div>
        ))}
      </div>
    </InfoCard>
  )
}
