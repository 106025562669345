import { Plus } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import {
  Input,
  Select,
  SelectItem,
  Button,
  Spacer,
  Chip,
} from '@nextui-org/react'

export const AddCustomField = ({ formData, setFormData }) => {
  const [fieldName, setFieldName] = useState(formData.name || '')
  const [fieldType, setFieldType] = useState(formData.type || 'text')
  const [choices, setChoices] = useState(formData.options || [])
  const [newChoice, setNewChoice] = useState('')

  const handleAddChoice = () => {
    if (newChoice.trim()) {
      setChoices([...choices, newChoice.trim()])
      setNewChoice('')
    }
  }

  useEffect(() => {
    setFormData({
      name: fieldName,
      type: fieldType,
      ...(fieldType === 'dropdown' ? { options: choices } : {}),
    })
  }, [fieldName, fieldType, choices, setFormData])

  return (
    <div className="p-4">
      <Input
        label="Field Name"
        placeholder="Enter field name"
        value={fieldName}
        onChange={e => setFieldName(e.target.value)}
        fullWidth
        className="p-1"
      />

      <Spacer y={1} />

      <Select
        label="Field Type"
        selectedKeys={new Set([fieldType])}
        onSelectionChange={selected => {
          const selectedValue = Array.from(selected).join('')
          setFieldType(selectedValue)
        }}
        placeholder="Select a field type"
        className="w-full p-1"
      >
        <SelectItem key="text" value="text">
          Text
        </SelectItem>
        <SelectItem key="email" value="email">
          Email
        </SelectItem>
        <SelectItem key="dropdown" value="dropdown">
          DropDown
        </SelectItem>
      </Select>

      {fieldType === 'dropdown' && (
        <>
          <Spacer y={1} />
          <Input
            label="Add Choice"
            placeholder="Enter choice"
            value={newChoice}
            onChange={e => setNewChoice(e.target.value)}
            fullWidth
            className="p-1"
          />
          <Button size="sm" onClick={handleAddChoice} className="mt-2 py-1">
            <Plus className={`text-base gap-0`} />
            Add Options
          </Button>
          <ul className="flex flex-row mt-2 overflow-auto">
            {choices.map((dropdown, index) => (
              <li key={index} className="p-1">
                <Chip radius="lg">{dropdown}</Chip>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}
