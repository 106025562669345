import { getFormFieldError } from 'helpers'
import React, { useRef, useEffect } from 'react'
import { fieldClasses } from './auto-complete-helper'

export const AddressAutoComplete = ({
  name = '',
  label = 'Your Address',
  placeholder = 'Type to search for an address',
  addressComponentName = '',
  register = () => {},
  setValue = () => {},
  errors = {},
  onPlaceSelected = () => {},
  className = '',
  ...rest
}) => {
  const inputRef = useRef()

  useEffect(() => {
    if (window.google && window.google.maps && inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['address'],
        },
      )

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        const address = place.formatted_address || ''
        if (typeof setValue === 'function') {
          setValue(name, address)
          setValue(addressComponentName, place?.address_components || [])
        }
        if (typeof onPlaceSelected === 'function') {
          onPlaceSelected(place)
        }
      })
    }
  }, [name, setValue, addressComponentName, onPlaceSelected])

  const fieldError = getFormFieldError(errors, name)

  const handleInputRef = e => {
    inputRef.current = e
    if (typeof register === 'function') {
      register(name).ref(e)
    }
  }

  return (
    <div className={`flex flex-col ${className}`}>
      <label htmlFor={name} className="mb-1 text-gray-500 text-base">
        {label}
      </label>
      <input
        ref={handleInputRef}
        name={name}
        {...rest}
        className={fieldClasses}
        placeholder={placeholder}
        id={name}
      />
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
