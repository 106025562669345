import React from 'react'

export const Unauthorized = () => {
  return (
    <div className="bg-[#1C2127] h-screen flex flex-col justify-center items-start pl-10">
      <div className="text-white text-[30px] font-medium mb-5">
        You are not authorized.
      </div>
      <div className="text-white text-[16px] font-light max-w-[360px] mb-10">
        You tried to access a page that you do not have permission to view or
        that does not exist.
      </div>
      <div className="absolute right-10">
        <div className="neon text-center text-[90px] font-varela tracking-wider text-[#5BE0B3] animate-flux">
          403
        </div>
        <div className="door-frame h-[495px] w-[295px] bg-[#8594A5] rounded-t-[90px] flex justify-center items-center">
          <div className="door h-[450px] w-[250px] bg-[#A0AEC0] rounded-t-[70px] relative">
            <div className="rectangle h-[70px] w-[25px] bg-[#CBD8E6] rounded-lg absolute top-[220px] left-[20px]" />
            <div className="handle h-[8px] w-[50px] bg-[#EBF3FC] rounded-lg absolute top-[250px] left-[30px]" />
            <div className="window h-[40px] w-[130px] bg-[#1C2127] rounded-sm mt-[80px] mx-auto relative">
              <div className="eye h-[5px] w-[15px] bg-white rounded-full absolute top-[15px] left-[25px] animate-eye" />
              <div className="eye h-[5px] w-[15px] bg-white rounded-full absolute top-[15px] left-[65px] animate-eye" />
              <div className="leaf h-[40px] w-[130px] bg-[#8594A5] rounded-sm mt-[80px] mx-auto animate-leaf" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
