import {
  generateCSV,
  downloadCSV,
  partnerTabActionsConst,
} from 'helpers/csv-helpers'

export const handleCurrentDataDownload = async (
  tableData,
  setIsDataDownloading,
  fetchDataByIdsApi,
  downloadCSVFilename,
  columnsToExclude = [],
  partnersTabAction = partnerTabActionsConst.Claims,
) => {
  setIsDataDownloading(true)
  const formIds = tableData?.data?.map(item => item.id)
  try {
    const response = await fetchDataByIdsApi(formIds)
    const csvContent = generateCSV(
      response?.data,
      columnsToExclude,
      partnersTabAction,
    )
    downloadCSV(csvContent, downloadCSVFilename)
  } catch (error) {
    console.error('Error downloading CSV File:', error)
  } finally {
    setIsDataDownloading(false)
  }
}

export const handleAllDataDownload = async (
  setIsDataDownloading = () => {},
  selectedFilter = '',
  setIsModalOpen = () => {},
  fetchAllDataDownload = () => {},
) => {
  setIsDataDownloading(true)
  try {
    const response = await fetchAllDataDownload(selectedFilter)
    if (response.status === 201) {
      setIsModalOpen(true)
    }
  } catch (error) {
    console.error('Failed to submit Download request. Please try again.', error)
  } finally {
    setIsDataDownloading(false)
  }
}
