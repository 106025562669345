export const damageTypes = [
  { label: 'Theft', value: 'theft' },
  { label: 'Door Ding', value: 'door_ding' },
  { label: 'Broken Window', value: 'broken_window' },
  { label: 'Other Damage', value: 'other_damage' },
  { label: 'Multiple Damage Types', value: 'multiple_damage' },
]

export const claimFormCheckboxOptions = {
  legalFormClause:
    'By checking this box, I certify that all information provided is truthful and accurate. I understand that this is a legal insurance form and that any fraudulent activity may result in legal consequences and the denial of my insurance claim.',
  termsAndConditions:
    'By checking this box, I agree to Park Guard’s terms of use as well as the terms of use of all partner companies involved in the parking and claims process.',
}

export const claimFormUserInstructions =
  'Please submit the claim form as soon as damage is discovered even if you do not have all the necessary information. Our team will work with you to gather more details after the form is submitted'

export const uploadFilesConfig = [
  {
    name: 'damagePhotos',
    label: 'Upload Photos of Damage',
    description: 'Max 5 images and 15MB total allowed',
    maxAllowedFiles: 5,
    maxTotalSize: 15 * 1024 * 1024,
    accept: 'image/*',
  },
  {
    name: 'receiptFiles',
    label: 'Upload Receipt',
    description: 'Max 2 images and 8MB total allowed',
    maxAllowedFiles: 2,
    accept: 'image/*',
  },
  {
    name: 'additionalFiles',
    label: 'Upload Additional Documents',
    description: 'Max 5 files and 8MB total allowed',
    maxAllowedFiles: 5,
    accept: 'image/*,application/pdf',
  },
]

export const claimFormLicenseInfoClause = {
  licenseInfo: 'I don’t know my license plate info',
}
