export const disableConsoleLogs = () => {
  ;['log', 'debug', 'info', 'warn', 'error'].forEach(method => {
    const original = console[method].bind(console)

    console[method] = (...args) => {
      if (method === 'error') {
        original(...args)
      } else {
        return
      }
    }
  })
}
