import React from 'react'
import { ToastMessageIcon } from './ToastMessageIcon'

const getToastBorderStyles = type => {
  switch (type) {
    case 'success':
      return 'border-green-500'
    case 'error':
      return 'border-red-500'
    case 'warning':
      return 'border-yellow-500'
    case 'info':
      return 'border-blue-500'
    default:
      return 'border-gray-500'
  }
}

export const Toast = ({ message, type }) => {
  return (
    <div
      className={`flex items-center p-4 border-l-4 rounded-lg shadow-lg bg-white dark:bg-gray-800 transform transition-transform duration-300 ease-in-out animate-slide-in ${getToastBorderStyles(type)}`}
    >
      <ToastMessageIcon type={type} />
      <div className="ml-4 text-sm font-medium text-gray-800 dark:text-gray-200">
        {message}
      </div>
    </div>
  )
}
