import ReCAPTCHA from 'react-google-recaptcha'
import React, { useEffect, useState } from 'react'
import { Button, Spinner } from '@nextui-org/react'
import { uploadFilesConfig } from './form-constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useToastMessage } from 'hooks/useToastMessage'
import { CustomLoader } from 'components/generics/loader'
import { TermsSection } from './form-sections/TermsSection'
import parkguardLogo from 'assets/images/park-guard-logo.png'
import { verifyClaimTemplate } from 'services/partnerService'
import { AddressSection } from './form-sections/AddressSection'
import { ClaimFormHeader } from './form-sections/ClaimFormHeader'
import { FileUploadSection } from './form-sections/FileUploadSection'
import { DescriptionSection } from './form-sections/DescriptionSection'
import { claimFormValidationSchema } from 'validations/claimFormSchema'
import { DamageDetailsSection } from './form-sections/DamageDetailsSection'
import { UploadProgressBar } from 'components/generics/upload-progress-bar'
import { useLocation, Navigate, useNavigate, useParams } from 'react-router-dom'
import { PersonalDetailsSection } from './form-sections/PersonalDetailsSection'
import {
  clearClaimFormData,
  renderCustomisedFormFields,
  handleClaimFileUploads,
  prepareSubmissionData,
  submitClaimFormData,
} from './form-helpers'

export const PartnerClaimForm = ({ setFormBgColor = () => {} }) => {
  const methods = useForm({
    resolver: yupResolver(claimFormValidationSchema),
    defaultValues: {
      legalFormClause: false,
      termsClause: false,
      damagePhotos: [],
      receiptFiles: [],
      additionalFiles: [],
      is_license_info_available: false,
    },
    shouldUnregister: false,
  })

  const location = useLocation()
  const { url_suffix } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [templateData, setTemplateData] = useState(null)
  const [verificationFailed, setVerificationFailed] = useState(false)
  const [submitting, setIsSubmitting] = useState(false)
  const [captchaToken, setCaptchaToken] = useState(null)
  const [uploadProgress, setUploadProgress] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const navigate = useNavigate()
  const { showToast } = useToastMessage()

  const verifyAndGetTemplate = async () => {
    try {
      const response = await verifyClaimTemplate(url_suffix)
      setTemplateData(response.data)
      setFormBgColor(response.data?.color_theme)
      setIsLoading(false)
    } catch (error) {
      console.error('Verification failed:', error)
      setVerificationFailed(true)
      setIsLoading(false)
    }
  }

  const onSubmit = async data => {
    if (!captchaToken) {
      showToast('Please complete the CAPTCHA.', 'error')
      return
    }

    try {
      setIsUploading(true)

      const uploadedFiles = await handleClaimFileUploads(
        data,
        setUploadProgress,
      )

      const preparedData = {
        ...data,
        ...uploadedFiles,
      }
      const formData = prepareSubmissionData(
        preparedData,
        templateData,
        templateData?.access_key,
      )

      setIsUploading(false)
      setIsSubmitting(true)
      await submitClaimFormData(
        formData,
        () => clearClaimFormData(methods.reset),
        navigate,
        showToast,
      )
    } catch (error) {
      console.error('Error during form submission:', error)
    } finally {
      setIsUploading(false)
      setIsSubmitting(false)
    }
  }
  const handleCaptchaChange = token => {
    setCaptchaToken(token)
  }

  useEffect(() => {
    verifyAndGetTemplate()
  }, [location.pathname])

  if (verificationFailed) {
    return <Navigate to="/unauthorized" />
  }
  return (
    <>
      {isUploading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-90">
          <UploadProgressBar progress={uploadProgress} />
        </div>
      )}

      {submitting && !isUploading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <CustomLoader />
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="p-6 w-full"
          >
            <ClaimFormHeader
              logoSrc={parkguardLogo}
              partnerName={templateData?.partner?.name}
            />
            <PersonalDetailsSection />
            <AddressSection />
            <DamageDetailsSection />

            {/* Customised Dynamic Form Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-4">
              {templateData &&
                renderCustomisedFormFields(
                  templateData.fields,
                  methods.register,
                  methods.control,
                  methods.formState.errors,
                )}
            </div>

            <DescriptionSection />
            <FileUploadSection uploadConfigs={uploadFilesConfig} />
            <TermsSection />
            <div className="flex justify-start mt-2 col-span-2 py-4">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
              />
            </div>
            <div className="flex col-span-2 item-center justify-center p-2">
              <Button
                type="submit"
                color="primary"
                variant="solid"
                isDisabled={submitting}
                className="w-full min-w-[300px] max-w-[300px] min-h-[45px] bg-gray-950"
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  )
}
