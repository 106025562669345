import React from 'react'
import { FormInput } from '../form-generics/FormInputField'
import { FormSelect } from '../form-generics/FormSelectField'

export const CustomisedFormField = ({ field, register, control, errors }) => {
  const { name, type, options } = field

  switch (type) {
    case 'email':
      return (
        <FormInput
          label={name}
          name={name}
          type="email"
          register={register}
          errors={errors}
          className="col-span-2 sm:col-span-1"
        />
      )

    case 'text':
      return (
        <FormInput
          label={name}
          name={name}
          type="text"
          register={register}
          errors={errors}
          className="col-span-2 sm:col-span-1"
        />
      )

    case 'dropdown': {
      const selectOptions = options.map(option => ({
        value: option.name,
        label: option.name,
      }))
      return (
        <FormSelect
          control={control}
          name={name}
          label={name}
          items={selectOptions}
          errors={errors}
          className="col-span-2 sm:col-span-1"
        />
      )
    }

    default:
      return null
  }
}
