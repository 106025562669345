import axiosInstance from './axiosConfig'
import { AdminEndPoints } from './api-constants'

export const getPartnerFormTemplates = async (page = 1, partnerId = '') => {
  return await axiosInstance.get(
    AdminEndPoints.getPartnerClaimTemplates(page, partnerId),
  )
}

export const addNewFormField = async (access_key, formData) => {
  return await axiosInstance.post(
    AdminEndPoints.addNewFormField(access_key),
    formData,
  )
}

export const deleteFormField = async field_id => {
  return await axiosInstance.delete(AdminEndPoints.deleteFormField(field_id))
}

export const updateFormField = async (field_id, formData) => {
  return await axiosInstance.put(
    AdminEndPoints.updateFormField(field_id),
    formData,
  )
}

export const updateFormColor = async (access_key, formColor) => {
  return await axiosInstance.put(AdminEndPoints.updateFormColor(access_key), {
    color_theme: formColor,
  })
}
