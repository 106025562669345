import React from 'react'
import { useFormContext } from 'react-hook-form'
import { AddressAutoComplete } from 'components/generics/auto-complete-address'

export const AddressSection = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="grid grid-cols-1 gap-6 pb-4 pt-3">
      <AddressAutoComplete
        label="Mailing Address"
        name="mailingAddress"
        register={register}
        setValue={setValue}
        addressComponentName="mailingAddressComponent"
        errors={errors}
      />
      <AddressAutoComplete
        label="Address of Parking Facility"
        name="addressOfParkingFacility"
        register={register}
        setValue={setValue}
        addressComponentName="parkingAddressComponent"
        errors={errors}
      />
    </div>
  )
}
