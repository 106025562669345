import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Users, FolderUser, ListPlus, FileCsv } from '@phosphor-icons/react'

export const PartnerSideBar = () => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <div className="flex flex-col relative top-24 min-h-[15rem] h-auto items-center ml-8 p-4 bg-white shadow-md rounded-xl w-48 md:w-60 mb-4 md:mb-0">
      <div className="mt-4 w-full">
        <ul className="gap-y-3 flex flex-col">
          <Link to="/partner/dashboard">
            <li
              className={`flex justify-between items-center p-2 rounded-lg ${pathname === '/partner/dashboard' ? 'bg-blue-100' : ''}`}
            >
              <span className="flex items-center space-x-2">
                <Users className="w-4 h-4 text-black" />
                <span>Customers</span>
              </span>
            </li>
          </Link>
          <Link to="/partner/dashboard/claim-submissions">
            <li
              className={`flex justify-between items-center p-2 rounded-lg ${pathname === '/partner/dashboard/claim-submissions' ? 'bg-blue-100' : ''}`}
            >
              <span className="flex items-center space-x-2">
                <ListPlus className="w-4 h-4 text-black" />
                <span className="whitespace-nowrap">Claims</span>
              </span>
            </li>
          </Link>
          <Link to="/partner/dashboard/reports-download">
            <li
              className={`flex justify-between items-center p-2 rounded-lg ${pathname === '/partner/dashboard/reports-download' ? 'bg-blue-100' : ''}`}
            >
              <span className="flex items-center space-x-2">
                <FileCsv className="w-4 h-4 text-black" />
                <span className="whitespace-nowrap">Reports Download</span>
              </span>
            </li>
          </Link>
          <Link to="/partner/dashboard/claim-forms">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/partner/dashboard/claim-forms' ? 'bg-blue-100' : ''}`}
            >
              <FolderUser className="w-4 h-4 text-black" />
              <span>Claim Form and URL</span>
            </li>
          </Link>

          <Link to="/partner/dashboard/monthly-reports">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/partner/dashboard/monthly-reports' ? 'bg-blue-100' : ''}`}
            >
              <FileCsv className="w-4 h-4 text-black" />
              <span>Monthly Reports</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  )
}
