import React from 'react'
import { getFormFieldError } from 'helpers'
import { Controller } from 'react-hook-form'
import { DateRangePicker } from '@nextui-org/react'
import { parseDate } from '@internationalized/date'

export const FormDateRangePicker = ({
  control = () => {},
  name = '',
  label = '',
  errors = {},
  className = '',
  disabled = false,
  ...rest
}) => {
  const fieldError = getFormFieldError(errors, name)

  const safeParseDate = dateStr => {
    try {
      return parseDate(dateStr)
    } catch {
      return null
    }
  }

  return (
    <div className={`flex flex-col ${className}`}>
      {disabled ? (
        <DateRangePicker
          label={label}
          isDisabled
          className="w-full"
          {...rest}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            const { value = [null, null], onChange } = field
            const dateRangeValue =
              value[0] && value[1]
                ? {
                    start: safeParseDate(value[0]),
                    end: safeParseDate(value[1]),
                  }
                : undefined

            return (
              <DateRangePicker
                label={label}
                value={dateRangeValue}
                onChange={range => {
                  if (range?.start && range?.end) {
                    onChange([range.start.toString(), range.end.toString()])
                  }
                }}
                className="w-full"
                {...rest}
              />
            )
          }}
        />
      )}
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
