import React, { useState } from 'react'
import { Card, CardBody } from '@nextui-org/react'
import { LoadScript } from '@react-google-maps/api'
import { GOOGLE_LIBRARIES } from 'helpers/app-constants'
import { PartnerClaimForm } from 'components/partner/claim-forms/ClaimForm'

export const ClaimFormPage = () => {
  const [formBgColor, setFormBgColor] = useState('#FFFFFF')
  return (
    <div className="flex flex-col h-full w-full bg-white lg:bg-gray-50">
      <div className="flex py-6 items-center justify-center">
        <div className="w-full max-w-4xl px-6 lg:px-24">
          <Card style={{ backgroundColor: formBgColor }}>
            <CardBody>
              <div className="flex flex-col md:flex-row items-center sm:mt-0">
                <div className="flex justify-center w-full">
                  <LoadScript
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_PLACES_API_KEY
                    }
                    libraries={GOOGLE_LIBRARIES}
                  >
                    <PartnerClaimForm setFormBgColor={setFormBgColor} />
                  </LoadScript>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}
