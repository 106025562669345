import React from 'react'
import { Chip } from '@nextui-org/react'
import { TrashIcon } from '@heroicons/react/outline'
import { truncateText, formatFileSize } from 'helpers'

export const FileList = ({ files, removeFile }) => (
  <ul className="mt-2">
    {files.map(
      (file, index) =>
        file?.name && (
          <div key={file.name + index} className="flex items-center gap-2">
            <Chip
              radius="sm"
              key={index}
              className="flex items-center mt-2 mb-2"
            >
              <div className="flex items-center min-w-24 max-w-24 overflow-hidden justify-between">
                {truncateText(file.name, 10)}

                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className="ml-2 text-red-500 hover:text-red-700 cursor-pointer"
                  aria-label={`Remove file ${file.name}`}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </Chip>
            <span className="text-sm text-gray-500">
              ({formatFileSize(file.size)})
            </span>
          </div>
        ),
    )}
  </ul>
)
