import './tailwind.css'
import App from './App'
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { store, persistor } from './store'
import reportWebVitals from './reportWebVitals'
import { NextUIProvider } from '@nextui-org/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { disableConsoleLogs } from 'helpers/disableConsoleLogs'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
const isProd = process.env.REACT_APP_CLIENT_ENV === 'production'

// Disable logs
if (isProd) {
  disableConsoleLogs()
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <NextUIProvider>
            <App />
          </NextUIProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

reportWebVitals()
