import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormInput } from '../form-generics/FormInputField'

export const PersonalDetailsSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-4">
      <FormInput
        label="First Name"
        name="firstName"
        register={register}
        errors={errors}
        className="col-span-2 sm:col-span-1"
      />
      <FormInput
        label="Last Name"
        name="lastName"
        register={register}
        errors={errors}
        className="col-span-2 sm:col-span-1"
      />
      <FormInput
        label="Email"
        name="userEmail"
        type="email"
        register={register}
        errors={errors}
        className="col-span-2"
      />
    </div>
  )
}
