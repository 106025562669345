import React from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react'

export const InformationModal = ({
  isOpen,
  onClose,
  title,
  body,
  footerActions,
  modalClassName,
  headerClassName,
  bodyClassName,
  footerClassName,
  dismissable = false,
}) => (
  <Modal
    backdrop="opaque"
    isOpen={isOpen}
    onOpenChange={onClose}
    isDismissable={dismissable}
    classNames={{
      backdrop:
        'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20',
      modal: modalClassName,
    }}
  >
    <ModalContent>
      <>
        <ModalHeader className={`flex flex-col gap-1 ${headerClassName}`}>
          {title}
        </ModalHeader>
        <ModalBody className={bodyClassName}>{body}</ModalBody>
        <ModalFooter className={footerClassName}>
          {footerActions?.map((action, index) => (
            <Button
              key={index}
              color={action.color}
              className={action.button_background}
              variant={action.variant || 'solid'}
              onPress={action.onPress || onClose}
            >
              {action.label}
            </Button>
          ))}
        </ModalFooter>
      </>
    </ModalContent>
  </Modal>
)
