import { useState, useEffect, useCallback } from 'react'

export const usePagination = fetchData => {
  const [data, setData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [query, setQuery] = useState('')
  const [filter, setFilter] = useState('')
  const [dateRange, setDateRange] = useState({ date_start: '', date_end: '' })

  const getPaginatedData = useCallback(
    async (page, q, f, start, end) => {
      try {
        setIsLoading(true)
        const response = await fetchData(page, q, f, start, end)

        if (response.status === 200) {
          const { count, results } = response.data
          setData(results)
          setTotalPages(Math.ceil(count / 50))
        } else {
          console.error('Failed to fetch data:', response.statusText)
        }
      } catch (error) {
        console.error('Failed to fetch data:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [fetchData],
  )

  const handlePageChange = useCallback(
    page => {
      setCurrentPage(page)
      getPaginatedData(
        page,
        query,
        filter,
        dateRange.date_start,
        dateRange.date_end,
      )
    },
    [getPaginatedData, query, filter, dateRange],
  )

  const handleSearch = useCallback(
    q => {
      setQuery(q)
      setCurrentPage(1)
      getPaginatedData(1, q, filter, dateRange.date_start, dateRange.date_end)
    },
    [getPaginatedData, filter, dateRange],
  )

  const handleFilter = useCallback(
    f => {
      const newFilter = filter === f ? '' : f
      setFilter(newFilter)
      setCurrentPage(1)
      getPaginatedData(
        1,
        query,
        newFilter,
        dateRange.date_start,
        dateRange.date_end,
      )
    },
    [getPaginatedData, filter, query, dateRange],
  )

  const handleDateRange = useCallback(
    (start, end) => {
      setDateRange({ date_start: start, date_end: end })
      setCurrentPage(1)
      getPaginatedData(1, query, filter, start, end)
    },
    [getPaginatedData, query, filter],
  )

  useEffect(() => {
    getPaginatedData(
      currentPage,
      query,
      filter,
      dateRange.date_start,
      dateRange.date_end,
    )
  }, [currentPage, query, filter, dateRange, getPaginatedData])

  return {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleSearch,
    handleFilter,
    handleDateRange,
  }
}
