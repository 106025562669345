import React from 'react'
import { Input } from '@nextui-org/react'
import { getFormFieldError } from 'helpers'

export const FormInput = ({
  label = '',
  name = '',
  className = '',
  disabled = false,
  register = () => {},
  errors = () => {},
  ...rest
}) => {
  const fieldError = getFormFieldError(errors, name)

  return (
    <div className={`flex flex-col ${className}`}>
      <Input
        label={label}
        name={name}
        {...register(name)}
        status={fieldError ? 'error' : 'default'}
        fullWidth
        {...rest}
        isDisabled={disabled}
      />
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
