import React from 'react'
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from '@nextui-org/react'
import { Sliders } from '@phosphor-icons/react'

export const FilterDropdown = ({
  options = [],
  value = null,
  onChange = () => {},
  label = 'Filter Records',
  Icon = Sliders,
  buttonClassName = '',
  iconClassName = '',
  menuClassName = '',
  itemClassName = '',
  varient = 'light',
}) => {
  const handleSelection = key => {
    const selectedOption = options.find(option => option.value === key)
    onChange(selectedOption)
  }

  const selectedLabel =
    options.find(option => option.value === value)?.label || label

  return (
    <Dropdown closeOnSelect>
      <DropdownTrigger>
        <button
          className={`flex items-center space-x-2 px-5 py-2 border rounded-xl shadow-sm focus:outline-none hover:bg-gray-100 min-w-40 min-h-10 ${buttonClassName}`}
        >
          <Icon className={`text-xl ${iconClassName}`} />
          <span className="text-sm text-gray-600">{selectedLabel}</span>
        </button>
      </DropdownTrigger>

      <DropdownMenu
        className={`${menuClassName}`}
        onAction={handleSelection}
        selectionMode="single"
        selectedKeys={value ? [value] : []}
        variant={varient}
      >
        {options.map(option => (
          <DropdownItem
            key={option.value}
            className={`text-black ${itemClassName}`}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
