import React from 'react'
import { Unauthorized } from 'components/generics/unauthorize/UnauthorizeComponent'

export const UnauthorizePage = () => {
  return (
    <div>
      <Unauthorized />
    </div>
  )
}
