import React from 'react'
import { UsaStates } from 'usa-states'
import { useFormContext } from 'react-hook-form'
import { FormInput } from '../form-generics/FormInputField'
import { FormSelect } from '../form-generics/FormSelectField'
import { FormDatePicker } from '../form-generics/FormDatePicker'
import { FormCheckbox } from '../form-generics/FormCheckboxField'
import { claimFormLicenseInfoClause, damageTypes } from '../form-constants'
import { FormDateRangePicker } from 'components/generics/date-range-picker'

export const DamageDetailsSection = () => {
  const usStates = new UsaStates()
  const stateItems = usStates.states.map(state => ({
    value: state.abbreviation,
    label: state.name,
  }))
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const isLicenseInfoAvailable = watch('is_license_info_available', false)

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-4">
      {!isLicenseInfoAvailable && (
        <>
          <FormSelect
            control={control}
            name="licensePlateState"
            label="License Plate State"
            placeholder="Select a state"
            items={stateItems}
            errors={errors}
            className="col-span-2 sm:col-span-1"
          />

          <FormInput
            label="License Plate Number"
            name="licensePlateInfo"
            register={register}
            errors={errors}
            className="col-span-2 sm:col-span-1"
            maxLength={7}
          />
        </>
      )}

      <FormCheckbox
        control={control}
        name="is_license_info_available"
        label={claimFormLicenseInfoClause.licenseInfo}
        className="col-span-2 text-justify"
      />

      {/* Date Parked */}
      <FormDateRangePicker
        control={control}
        name="dateParked"
        label="Date Parked (Range)"
        errors={errors}
        className="col-span-2 sm:col-span-1"
      />

      {/* Type of Damage */}
      <FormSelect
        control={control}
        name="typeOfDamage"
        label="Type of Damage"
        placeholder="Select damage type"
        items={damageTypes}
        errors={errors}
        className="col-span-2 sm:col-span-1"
      />

      {/* Car Information */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 col-span-2">
        <FormInput
          label="Car Make"
          name="carInformation.make"
          register={register}
          errors={errors}
        />
        <FormInput
          label="Car Model"
          name="carInformation.model"
          register={register}
          errors={errors}
        />
        <FormInput
          label="Car Year"
          name="carInformation.year"
          type="number"
          min="1951"
          max={new Date().getFullYear()}
          pattern="\d*"
          register={register}
          errors={errors}
        />
      </div>

      {/* Dates Damage Discovered */}
      <FormDatePicker
        control={control}
        name="dateDamageDiscovered"
        label="Date Damage Discovered"
        errors={errors}
        className="col-span-2 sm:col-span-1"
      />

      <FormInput
        label="Phone Number"
        name="phoneNumber"
        type="tel"
        maxLength="11"
        placeholder="123-456-7890"
        register={register}
        errors={errors}
        className="col-span-2 sm:col-span-1"
      />
    </div>
  )
}
