import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  authenticateUser,
  generateQRCode,
  verifyOTP,
} from 'services/userService'
import { setCurrentUser } from 'redux-slices/currentUserSlice'

const roleToPath = {
  parkguard_employee: '/admin/dashboard',
  partner_employee: '/partner/dashboard',
  default: '/',
}

const storeToken = token => {
  if (token) {
    localStorage.setItem('accessToken', token)
  }
}

const removeToken = () => {
  localStorage.removeItem('accessToken')
}

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [requires2FASetup, setRequires2FASetup] = useState(false)
  const [requires2FAVerification, setRequires2FAVerification] = useState(false)
  const [authToken, setAuthToken] = useState('')
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = { username: '', password: '' }

  const handleSubmit = async values => {
    try {
      setIsLoading(true)
      removeToken()

      const response = await authenticateUser(values.username, values.password)
      const { token, requires_2fa_setup, requires_2fa_verification } =
        response.data

      if (requires_2fa_setup) {
        setAuthToken(token)
        setRequires2FASetup(true)
        const qrResponse = await generateQRCode(token)
        setQrCodeUrl(qrResponse?.data?.qr_code_url)
      } else if (requires_2fa_verification) {
        setAuthToken(token)
        setRequires2FAVerification(true)
      }
    } catch (error) {
      console.error('Login failed:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOTPVerification = async otpCode => {
    try {
      setIsLoading(true)
      const response = await verifyOTP(otpCode, authToken)
      const { user, token } = response.data

      storeToken(token)
      dispatch(setCurrentUser(user))

      const role = user.role?.toLowerCase()
      const path = roleToPath[role] || roleToPath.default
      navigate(path)
    } catch (error) {
      console.error('OTP verification failed:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    initialValues,
    handleSubmit,
    handleOTPVerification,
    isLoading,
    requires2FASetup,
    requires2FAVerification,
    qrCodeUrl,
  }
}
