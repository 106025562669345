import React from 'react'
import { truncateText } from 'helpers'

export const UploadProgressBar = ({ progress }) => {
  return (
    <div className="w-4/5 sm:w-3/4 md:w-2/3 lg:w-1/2 mx-auto bg-white p-6 rounded-lg shadow-lg">
      <div className="flex justify-center p-2">
        <h3 className="text-xs md:text-base font-semibold text-gray-500 mb-4">
          Uploading Your Files. Please Wait...
        </h3>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {progress && Object.entries(progress).length > 0 ? (
          Object.entries(progress).map(([fileName, value]) => (
            <div key={fileName} className="flex flex-col items-center">
              <div className="relative w-16 h-16">
                <svg className="w-16 h-16">
                  <circle
                    cx="50%"
                    cy="50%"
                    r="24"
                    className="stroke-gray-300 fill-none"
                    strokeWidth="4"
                  />
                  <circle
                    cx="50%"
                    cy="50%"
                    r="24"
                    className="stroke-blue-500 fill-none"
                    strokeWidth="4"
                    strokeDasharray="150"
                    strokeDashoffset={`${150 - (150 * value) / 100}`}
                    style={{
                      transition: 'stroke-dashoffset 0.3s ease-in-out',
                    }}
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-sm font-medium text-blue-600">
                  {value}%
                </span>
              </div>
              <span className="text-sm text-gray-600 mt-2 truncate">
                {truncateText(fileName, 10)}
              </span>
            </div>
          ))
        ) : (
          <div className="col-span-full text-gray-500">
            No uploads in progress...
          </div>
        )}
      </div>
    </div>
  )
}
