import React from 'react'

export const ClaimFormHeader = ({ logoSrc, partnerName }) => (
  <div className="flex items-center md:gap-3 mb-1 pb-4">
    <div className="flex basis-1/2 md:basis-1/3">
      <img src={logoSrc} alt="Logo" className="w-30 h-10" />
    </div>
    <div className="flex basis-1/2 items-start">
      {partnerName && (
        <span className="text-xs md:text-base text-gray-950 font-bold">
          Claim Form {partnerName.toUpperCase()}
        </span>
      )}
    </div>
  </div>
)
