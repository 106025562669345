import { Spinner } from '@nextui-org/react'
import { useParams } from 'react-router-dom'
import { getCustomerDetails } from 'services/customerService'
import React, { useState, useEffect, useCallback } from 'react'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'

export const CustomerDetailsView = () => {
  const { customer_id: customerId } = useParams()
  const [customerData, setCustomerData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getCustomerAllDetail = useCallback(async () => {
    try {
      const response = await getCustomerDetails(customerId)
      if (response.status === 200) {
        setCustomerData(response.data)
      }
    } catch (error) {
      console.error('Failed to get customer details. Please try again.', error)
    } finally {
      setIsLoading(false)
    }
  }, [customerId])

  useEffect(() => {
    getCustomerAllDetail()
  }, [getCustomerAllDetail])

  if (isLoading) {
    return (
      <div className="flex justify-center py-24">
        <Spinner color="default" size="lg" />
      </div>
    )
  }

  if (!customerData) {
    return (
      <div className="flex justify-center py-24">
        <p className="text-gray-500">No customer details available.</p>
      </div>
    )
  }

  const {
    first_name,
    last_name,
    email,
    phone_number,
    address,
    city,
    state,
    zipcode,
    pg_identifier,
    start_date,
    end_date,
    created_at,
    additional_info,
  } = customerData

  return (
    <div className="flex flex-col h-full w-full bg-gray-100 font-sans antialiased">
      <AppNavBar />
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-full max-w-2xl p-8 bg-white rounded-xl shadow-lg space-y-6">
          <div className="text-center space-y-2">
            <h1 className="text-3xl font-semibold text-gray-800">
              Customer Details
            </h1>
            <p className="text-lg text-gray-600">Park Guard Coverage Hub</p>
          </div>
          <div className="space-y-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-700">
                Personal Information
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                <div>
                  <label className="text-gray-500 text-sm">Full Name</label>
                  <p className="text-lg font-medium text-gray-800">{`${first_name ?? 'N/A'} ${last_name ?? 'N/A'}`}</p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">Email</label>
                  <p className="text-lg font-medium text-gray-800">
                    {email ?? 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">Phone Number</label>
                  <p className="text-lg font-medium text-gray-800">
                    {phone_number ?? 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">Address</label>
                  <p className="text-lg font-medium text-gray-800">
                    {address ?? 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">City</label>
                  <p className="text-lg font-medium text-gray-800">
                    {city ?? 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">State</label>
                  <p className="text-lg font-medium text-gray-800">
                    {state ?? 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">Zip Code</label>
                  <p className="text-lg font-medium text-gray-800">
                    {zipcode ?? 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                <div>
                  <label className="text-gray-500 text-sm">PG Identifier</label>
                  <p className="text-lg font-medium text-gray-800">
                    {pg_identifier ?? 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">Start Date</label>
                  <p className="text-lg font-medium text-gray-800">
                    {start_date
                      ? new Date(start_date).toLocaleDateString()
                      : 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">End Date</label>
                  <p className="text-lg font-medium text-gray-800">
                    {end_date ? new Date(end_date).toLocaleDateString() : 'N/A'}
                  </p>
                </div>
                <div>
                  <label className="text-gray-500 text-sm">Created Date</label>
                  <p className="text-lg font-medium text-gray-800">
                    {created_at
                      ? new Date(created_at).toLocaleDateString()
                      : 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            {additional_info && Object.keys(additional_info).length > 0 && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                {Object.entries(additional_info).map(([key, value], index) => (
                  <div key={index}>
                    <label className="text-gray-500 text-sm capitalize">
                      {key.replace(/_/g, ' ')}
                    </label>
                    <p className="text-lg font-medium text-gray-800">{value}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
