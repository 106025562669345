import React from 'react'
import { Button, Tooltip } from '@nextui-org/react'
import { Faders, Broom } from '@phosphor-icons/react'
import { FormDateRangePicker } from 'components/generics/date-range-picker'

export const DateRangeFilterForm = ({
  control,
  errors,
  handleSubmit,
  onDateRangeSubmit,
  onDateRangeCancel,
  watch,
}) => {
  const dateRange = watch('dateRange')
  const isApplyDisabled = !dateRange || dateRange.every(date => date === null)

  return (
    <div className="basis-1/6 mx-3">
      <form onSubmit={handleSubmit(onDateRangeSubmit)}>
        <div className="flex items-center">
          <div className="basis-9/12">
            <FormDateRangePicker
              control={control}
              name="dateRange"
              label="Filter By Created At"
              errors={errors}
            />
          </div>
          <div className="basis-3/12 flex flex-col gap-2">
            <Tooltip content="Clear Filter">
              <Button
                auto
                type="button"
                onClick={onDateRangeCancel}
                className="bg-transparent border-none p-0 h-6 w-6"
              >
                <Broom size={20} />
              </Button>
            </Tooltip>
            <Tooltip content="Apply Filter">
              <Button
                auto
                type="submit"
                className="bg-transparent border-none p-0 h-6 w-6"
                isDisabled={isApplyDisabled || !!errors.dateRange}
              >
                <Faders size={20} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}
