import React from 'react'
import { Textarea } from '@nextui-org/react'
import { getFormFieldError } from 'helpers'

export const FormTextarea = ({
  label = '',
  name = '',
  className = '',
  disabled = false,
  register = () => {},
  errors = () => {},
  placeholder = '',
  minRows = 3,
  maxRows = 8,
  ...rest
}) => {
  const fieldError = getFormFieldError(errors, name)

  return (
    <div className={`flex flex-col ${className}`}>
      <Textarea
        label={label}
        name={name}
        placeholder={placeholder}
        {...register(name)}
        minRows={minRows}
        maxRows={maxRows}
        status={fieldError ? 'error' : 'default'}
        fullWidth
        isDisabled={disabled}
        {...rest}
      />
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
