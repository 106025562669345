import React from 'react'

export const InfoCard = ({ title, children }) => {
  return (
    <div className="p-4 border border-gray-200 rounded-lg shadow-sm mb-4">
      <h2 className="font-bold text-xl mb-4 text-gray-600">{title}</h2>
      {children}
    </div>
  )
}
