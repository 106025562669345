import React from 'react'
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from '@nextui-org/react'

export const DefaultTableComponent = ({
  columns = [],
  data = [],
  renderActions,
  columnsExclude = [],
}) => {
  const renderCell = (item, columnKey) => {
    let cellValue = item[columnKey]

    if (typeof cellValue === 'object' && cellValue !== null) {
      cellValue = Object.keys(cellValue).join(', ')
    }

    switch (columnKey) {
      case 'actions':
        return renderActions ? renderActions(item) : null
      default:
        return cellValue !== null && cellValue !== undefined ? cellValue : 'N/A'
    }
  }

  const filteredColumns = columns.filter(
    column => !columnsExclude.includes(column.name),
  )

  return (
    <Table aria-label="Default Table" className="w-full">
      <TableHeader>
        {filteredColumns.map(column => (
          <TableColumn
            key={column.uid}
            align={column.uid === 'actions' ? 'center' : 'start'}
            className={column.width || 'w-auto'}
          >
            {column.name}
          </TableColumn>
        ))}
      </TableHeader>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={item.id || index}>
            {filteredColumns.map(column => (
              <TableCell key={column.uid}>
                {renderCell(item, column.uid)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
