import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { InfoCard } from './InfoCard'
import { ImageSlider } from './ImageSlider'
import { Button, Spinner } from '@nextui-org/react'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'
import { getClaimSubmissionOfClient } from 'services/partnerService'
import {
  renderClaimInfo,
  renderCarDamageInfo,
  renderAdditionalFields,
} from './ClaimViewHelper'
import { generateCustomerClaimSubmissionPDF } from './claim-view-helper/create-claim-view-pdf'

export const ClaimFormSingleView = () => {
  const { claim_id: claimId } = useParams()
  const [claimData, setClaimData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [showSlider, setShowSlider] = useState(false)
  const [sliderImages, setSliderImages] = useState([])

  const getClaimSubmission = useCallback(async () => {
    try {
      const response = await getClaimSubmissionOfClient(claimId)
      if (response.status === 200) {
        setClaimData(response?.data)
      }
    } catch (error) {
      console.error('Failed to get client submission. Please try again.', error)
    } finally {
      setIsLoading(false)
    }
  }, [claimId])

  useEffect(() => {
    getClaimSubmission()
  }, [getClaimSubmission])

  const handleDownloadPDF = () => {
    generateCustomerClaimSubmissionPDF(claimData)
  }

  const handleOpenSlider = useCallback(images => {
    setSliderImages(images)
    setShowSlider(true)
  }, [])

  const renderClaimImage = useCallback(
    (imageUrl, index, className, onClick) => (
      <Button key={index} className={className} onClick={onClick}>
        <img
          src={imageUrl}
          alt={`Damage ${index + 1}`}
          className="object-cover w-full max-h-[500px]"
          crossOrigin="anonymous"
        />
      </Button>
    ),
    [],
  )

  return (
    <div className="flex flex-col h-full w-full bg-white lg:bg-gray-50">
      <AppNavBar />
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex py-6 items-center justify-center w-full">
          <div className="w-full px-8">
            <div className="flex justify-end">
              <Button
                color="primary"
                auto
                onClick={handleDownloadPDF}
                className="bg-[#292728]"
              >
                Download Claim
              </Button>
            </div>
            <div className="p-6 w-full max-w-5xl mx-auto relative">
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-6 mb-2 pb-1 ${showSlider ? 'opacity-50' : ''}`}
              >
                {renderClaimInfo(claimData)}
                {renderCarDamageInfo(claimData)}
              </div>
              {claimData?.claim_description && (
                <InfoCard title="Description of Damage or Loss">
                  <p className="text-gray-600">{claimData.claim_description}</p>
                </InfoCard>
              )}
              {claimData?.additional_fields_responses &&
                Object.keys(claimData?.additional_fields_responses).length >
                  0 && <div>{renderAdditionalFields(claimData)}</div>}

              <InfoCard title="Claim Receipt">
                <div
                  id="receipt-section"
                  className="grid grid-cols-1 md:grid-cols-5 gap-4"
                >
                  {claimData?.claim_amount_receipts_list?.map(
                    (imageUrl, index) =>
                      renderClaimImage(
                        imageUrl,
                        index,
                        'cursor-pointer w-full h-full p-0 bg-transparent border-none',
                        () =>
                          handleOpenSlider(
                            claimData.claim_amount_receipts_list,
                          ),
                      ),
                  )}
                </div>
              </InfoCard>

              <InfoCard title="Damage Photos">
                <div
                  id="damage-photos-section"
                  className="grid grid-cols-1 md:grid-cols-5 gap-4"
                >
                  {claimData?.damage_details_images_list?.map(
                    (imageUrl, index) =>
                      renderClaimImage(
                        imageUrl,
                        index,
                        'cursor-pointer w-full h-full p-0 bg-transparent border-none',
                        () =>
                          handleOpenSlider(
                            claimData.damage_details_images_list,
                          ),
                      ),
                  )}
                </div>
              </InfoCard>

              {showSlider && (
                <ImageSlider
                  allImages={sliderImages}
                  onClose={() => setShowSlider(false)}
                />
              )}

              <InfoCard title="Additional Files">
                <div className="flex gap-4 justify-start">
                  {claimData?.additional_files_list?.length > 0 ? (
                    claimData.additional_files_list.map((fileUrl, index) => (
                      <Button
                        key={index}
                        color="primary"
                        auto
                        className="max-w-48 bg-[#292728]"
                        onClick={() => window.open(fileUrl, '_blank')}
                      >
                        View File {index + 1}
                      </Button>
                    ))
                  ) : (
                    <p className="text-center text-gray-500">
                      No files present.
                    </p>
                  )}
                </div>
              </InfoCard>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
