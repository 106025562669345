import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'

const ProtectedRoutes = ({ allowedRoles }) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const token = localStorage.getItem('accessToken')

  const isAuthenticated = !!token && currentUser

  if (!isAuthenticated) {
    return <Navigate to="/" />
  }

  if (!allowedRoles.includes(currentUser.role)) {
    return <Navigate to="/unauthorized" />
  }

  return <Outlet />
}

ProtectedRoutes.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ProtectedRoutes
