import React from 'react'
import { Button } from '@nextui-org/react'
import { ArrowLineDown, Download } from '@phosphor-icons/react'

export const DownloadCSVButtonGroup = ({
  isDataDownloading,
  tableData,
  handleCurrentDataDownload,
  setIsDataDownloading,
  setIsAttentionOpen,
  getClientDataByIds,
  downloadCSVFilename,
  partnerTabAction,
  columnsToExcludeFromCsv = [],
}) => {
  return (
    <div className="flex justify-start w-full gap-4">
      <Button
        className="ml-4 py-3 min-w-48 items-center hover:bg-gold-100 text-black text-xs"
        radius="sm"
        variant="bordered"
        color="solid"
        startContent={<ArrowLineDown />}
        isDisabled={isDataDownloading}
        onClick={() =>
          handleCurrentDataDownload(
            tableData,
            setIsDataDownloading,
            getClientDataByIds,
            downloadCSVFilename,
            columnsToExcludeFromCsv,
            partnerTabAction,
          )
        }
      >
        Download Current Records
      </Button>
      <Button
        className="py-3 min-w-40 items-center bg-gray-950 text-white text-xs"
        radius="sm"
        variant="solid"
        startContent={<Download />}
        isDisabled={isDataDownloading}
        onClick={() => setIsAttentionOpen(true)}
      >
        Request All Records
      </Button>
    </div>
  )
}
