import * as Yup from 'yup'

export const claimFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  userEmail: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  mailingAddress: Yup.string().required('Mailing Address is required'),
  mailingAddressComponent: Yup.array(),
  dateParked: Yup.array()
    .of(Yup.string().required('Both start and end dates are required'))
    .required('Date range is required')
    .min(2, 'Please select a start and end date'),
  carInformation: Yup.object().shape({
    make: Yup.string().required('Car make is required'),
    model: Yup.string().required('Car model is required'),
    year: Yup.number()
      .typeError('Year must be a number')
      .positive('Year must be positive')
      .integer('Year must be an integer')
      .min(1951, 'Year must be greater than 1950')
      .max(new Date().getFullYear(), 'Year cannot be in the future')
      .test(
        'len',
        'Year must be at most 4 digits',
        val => val && val.toString().length <= 4,
      ),
  }),
  is_license_info_available: Yup.boolean().default(false),
  licensePlateInfo: Yup.string().when('is_license_info_available', {
    is: false,
    then: () => Yup.string().required('License Plate Info is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  licensePlateState: Yup.string().when('is_license_info_available', {
    is: false,
    then: () => Yup.string().required('License Plate State is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  typeOfDamage: Yup.string().required('Type of Damage is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  dateDamageDiscovered: Yup.date()
    .required('Date Damage Discovered is required')
    .max(new Date(), 'Date cannot be in the future'),
  addressOfParkingFacility: Yup.string().required(
    'Address of Parking Facility is required',
  ),
  parkingAddressComponent: Yup.array(),
  legalFormClause: Yup.bool().oneOf(
    [true],
    'You must acknowledge this is a legal form',
  ),
  termsClause: Yup.bool().oneOf([true], 'You must agree to our terms'),
  claim_description: Yup.string().max(
    1000,
    'Description must not exceed 1000 characters',
  ),
  damagePhotos: Yup.array()
    .min(0)
    .of(
      Yup.mixed()
        .test('fileType', 'Unsupported file format', value =>
          value
            ? ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
            : true,
        )
        .test('fileSize', 'File is too large - (max 15MB allowed)', value =>
          value ? value.size <= 15728640 : true,
        ),
    ),
  receiptFiles: Yup.array()
    .min(0)
    .of(
      Yup.mixed()
        .test('fileType', 'Unsupported file format', value =>
          value
            ? ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
            : true,
        )
        .test('fileSize', 'File is too large - (max 8MB allowed)', value =>
          value ? value.size <= 8388608 : true,
        ),
    ),
  additionalFiles: Yup.array()
    .min(0)
    .of(
      Yup.mixed()
        .test('fileType', 'Unsupported file format', value =>
          value
            ? [
                'application/pdf',
                'image/jpeg',
                'image/jpg',
                'image/png',
                'image/gif',
              ].includes(value.type)
            : true,
        )
        .test('fileSize', 'File is too large - (max 8MB allowed)', value =>
          value ? value.size <= 8388608 : true,
        ),
    ),
})
