import React from 'react'
import { CustomisedFormField } from '../dynamic-form-fields'
import {
  generateBatchPresignedUrls,
  submitPartnerClaimForm,
} from 'services/partnerService'

const addressMappingFields = {
  info: ['street_number', 'route'],
  state: ['administrative_area_level_1'],
  city: ['locality'],
  zipCode: ['postal_code_suffix', 'postal_code'],
}

export const mapClaimFormDataToApiFormat = (
  formData,
  templateData,
  template_access_key,
  mappedMailingAddress = {},
  mappedParkingAddress = {},
) => {
  const apiData = {
    damage_details_images_list:
      formData.damagePhotos?.map(photo => photo || null) || [],
    claim_amount_receipts_list:
      formData.receiptFiles?.map(photo => photo || null) || [],
    additional_files_list:
      formData.additionalFiles?.map(doc => doc || null) || [],
    first_name: formData.firstName || '',
    last_name: formData.lastName || '',
    email: formData.userEmail || '',
    phone_number: formData.phoneNumber || '',
    parking_start_date: formData.dateParked?.[0]
      ? new Date(formData.dateParked[0]).toISOString().split('T')[0]
      : null,
    parking_end_date: formData.dateParked?.[1]
      ? new Date(formData.dateParked[1]).toISOString().split('T')[0]
      : null,
    car_information: `${formData.carInformation?.make || ''} ${formData.carInformation?.model || ''} ${formData.carInformation?.year || ''}`,
    license_plate_info: formData.licensePlateInfo || '',
    license_plate_state: formData.licensePlateState || '',
    type_of_damage: formData.typeOfDamage || '',
    damage_discovery_date: formData.dateDamageDiscovered
      ? new Date(formData.dateDamageDiscovered).toISOString().split('T')[0]
      : '',
    claim_description: formData.claim_description || '',
    template: template_access_key || '',
    mailing_address_info: mappedMailingAddress.info || '',
    mailing_city: mappedMailingAddress.city || '',
    mailing_state: mappedMailingAddress.state || '',
    mailing_zip_code: mappedMailingAddress.zipCode || '',
    parking_address_info: mappedParkingAddress.info || '',
    parking_city: mappedParkingAddress.city || '',
    parking_state: mappedParkingAddress.state || '',
    parking_zip_code: mappedParkingAddress.zipCode || '',
    is_license_info_available: !formData.is_license_info_available || false,
  }

  const additionalFieldsResponses = {}
  if (templateData?.fields) {
    templateData.fields.forEach(field => {
      if (formData[field.name]) {
        additionalFieldsResponses[field.name.toLowerCase().replace(/ /g, '_')] =
          formData[field.name]
      }
    })
  }

  apiData.additional_fields_responses = JSON.stringify(
    additionalFieldsResponses,
  )
  return apiData
}

export const mapAddressComponents = (
  addressComponents = [],
  mappingFields = {},
) => {
  if (
    !Array.isArray(addressComponents) ||
    typeof mappingFields !== 'object' ||
    mappingFields === null
  ) {
    return {}
  }

  const result = {}
  for (const [key, types] of Object.entries(mappingFields)) {
    if (Array.isArray(types)) {
      const matchedComponents = addressComponents
        .filter(
          component =>
            component &&
            Array.isArray(component.types) &&
            types.some(type => component.types.includes(type)),
        )
        .map(component => component.long_name || '')

      result[key] = matchedComponents.join(' ') || ''
    } else {
      result[key] = ''
    }
  }

  return result
}

export const prepareFormDataForSubmission = (
  data,
  templateData,
  templateAccessKey,
) => {
  const mappedMailingAddress = mapAddressComponents(
    data?.mailingAddressComponent,
    addressMappingFields,
  )
  const mappedParkingAddress = mapAddressComponents(
    data?.parkingAddressComponent,
    addressMappingFields,
  )

  const mappedData = mapClaimFormDataToApiFormat(
    data,
    templateData,
    templateAccessKey,
    mappedMailingAddress,
    mappedParkingAddress,
  )

  const formData = new FormData()

  Object.keys(mappedData).forEach(key => {
    if (
      ![
        'damage_details_images_list',
        'claim_amount_receipts_list',
        'additional_files_list',
      ].includes(key)
    ) {
      formData.append(key, mappedData[key])
    }
  })
  ;(mappedData.damage_details_images_list || []).forEach((url, index) => {
    formData.append(`damage_details_images_list[${index}]`, url)
  })
  ;(mappedData.claim_amount_receipts_list || []).forEach((url, index) => {
    formData.append(`claim_amount_receipts_list[${index}]`, url)
  })
  ;(mappedData.additional_files_list || []).forEach((url, index) => {
    formData.append(`additional_files_list[${index}]`, url)
  })

  return formData
}

export const clearClaimFormData = reset => {
  reset({
    legalFormClause: false,
    termsClause: false,
    damagePhotos: [],
    receiptFiles: [],
    additional_files: [],
    firstName: '',
    lastName: '',
    userEmail: '',
    mailingAddress: '',
    dateParked: null,
    licensePlateInfo: '',
    licensePlateState: '',
    typeOfDamage: '',
    carInformation: { make: '', model: '', year: '' },
    dateDamageDiscovered: null,
    phoneNumber: '',
    addressOfParkingFacility: '',
    claim_description: '',
  })
}

export const renderCustomisedFormFields = (
  fields = [],
  register = () => {},
  control = () => {},
  errors = {},
) => {
  return fields.map(field => (
    <CustomisedFormField
      key={field.name}
      field={field}
      register={register}
      control={control}
      errors={errors}
    />
  ))
}

export const uploadFilesToS3 = async (files, presignedUrls, setProgress) => {
  try {
    const uploadPromises = files.map(file => {
      const uploadUrl = presignedUrls[file.name]
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('PUT', uploadUrl, true)
        xhr.setRequestHeader('Content-Type', file.type)

        xhr.upload.onprogress = event => {
          if (event.lengthComputable) {
            const percentage = Math.round((event.loaded / event.total) * 100)
            setProgress(prev => ({
              ...prev,
              [file.name]: percentage,
            }))
          }
        }

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(uploadUrl.split('?')[0])
          } else {
            reject(new Error(`Failed to upload file: ${file.name}`))
          }
        }

        xhr.onerror = () =>
          reject(new Error(`Failed to upload file: ${file.name}`))
        xhr.send(file)
      })
    })

    const uploadedUrls = await Promise.all(uploadPromises)
    return uploadedUrls
  } catch (error) {
    console.error('Error during batch file upload:', error)
    throw error
  }
}

export const handleClaimFileUploads = async (data, setUploadProgress) => {
  const allFiles = [
    ...data.damagePhotos,
    ...data.receiptFiles,
    ...data.additionalFiles,
  ]

  if (allFiles.length === 0) {
    return {
      damagePhotos: [],
      receiptFiles: [],
      additionalFiles: [],
    }
  }

  try {
    const fileList = allFiles.map(file => ({
      name: file.name,
      type: file.type,
    }))

    const {
      data: { urls: presignedUrls },
    } = await generateBatchPresignedUrls(fileList)

    if (!presignedUrls || typeof presignedUrls !== 'object') {
      throw new Error('Invalid presigned URLs received.')
    }

    const uploadedDamagePhotoUrls = await uploadFilesToS3(
      data.damagePhotos,
      presignedUrls,
      setUploadProgress,
    )
    const uploadedReceiptFileUrls = await uploadFilesToS3(
      data.receiptFiles,
      presignedUrls,
      setUploadProgress,
    )
    const uploadedAdditionalFileUrls = await uploadFilesToS3(
      data.additionalFiles,
      presignedUrls,
      setUploadProgress,
    )

    return {
      damagePhotos: uploadedDamagePhotoUrls,
      receiptFiles: uploadedReceiptFileUrls,
      additionalFiles: uploadedAdditionalFileUrls,
    }
  } catch (error) {
    console.error('File upload failed:', error)
    throw new Error('File upload failed. Please try again.')
  }
}

export const prepareSubmissionData = (
  data,
  templateData,
  templateAccessKey,
) => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid form data.')
  }
  if (!templateData || typeof templateData !== 'object') {
    throw new Error('Invalid template data.')
  }
  if (!templateAccessKey) {
    throw new Error('Template access key is required.')
  }

  return prepareFormDataForSubmission(data, templateData, templateAccessKey)
}

export const submitClaimFormData = async (
  formData,
  reset,
  navigate,
  showToast,
) => {
  try {
    const response = await submitPartnerClaimForm(formData)

    if (response.status === 201) {
      reset()
      navigate('/claim-thank-you')
      return { success: true }
    }
  } catch (error) {
    console.error('Failed to submit form:', error)
    showToast('Failed to submit form. Please try again.', 'error')
  }
}
