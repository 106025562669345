import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import {
  formatMailingAddress,
  formatAddressFields,
  addressFieldsNames,
} from './claim-view-helper'

const formatAndCapitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const generateCustomerClaimSubmissionPDF = async claimData => {
  const pdf = new jsPDF('p', 'mm', 'a4')
  const pageWidth = pdf.internal.pageSize.getWidth()
  const pageHeight = pdf.internal.pageSize.getHeight()
  const rightMargin = 10
  const labelX = 10
  let yOffset = 20

  pdf.setFontSize(22)
  pdf.setTextColor('#333')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Claim Submission Report', pageWidth / 2, yOffset, {
    align: 'center',
  })

  yOffset += 5
  pdf.setDrawColor(200)
  pdf.line(10, yOffset, pageWidth - 10, yOffset)

  yOffset += 15
  pdf.setFontSize(16)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Claim Information', 10, yOffset)

  yOffset += 8
  pdf.setFontSize(12)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Claim ID:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.id}`, 35, yOffset)

  pdf.setFont('helvetica', 'bold')
  pdf.text('Status:', pageWidth / 2, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.status}`, pageWidth / 2 + 20, yOffset)

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('Submitted At:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${new Date(claimData.created_at).toLocaleString()}`, 40, yOffset)

  pdf.setFont('helvetica', 'bold')
  pdf.text('Claim Amount Paid:', pageWidth / 2, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(
    `${claimData.claim_amount_paid || 'N/A'}`,
    pageWidth / 2 + 45,
    yOffset,
  )

  yOffset += 17
  pdf.setFontSize(16)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Customer Information', labelX, yOffset)

  yOffset += 8
  pdf.setFontSize(12)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Name:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.first_name} ${claimData.last_name}`, 30, yOffset)

  pdf.setFont('helvetica', 'bold')
  pdf.text('Email:', pageWidth / 2, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.email}`, pageWidth / 2 + 15, yOffset)

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('Phone:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.phone_number}`, 30, yOffset)

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('Mailing Address:', labelX, yOffset)
  pdf.setFont('helvetica', 'normal')
  const homeAddressX = 45
  const homeAddressMaxWidth = pageWidth - homeAddressX - rightMargin
  const homeAddressLines = pdf.splitTextToSize(
    `${
      claimData?.mailing_address_info
        ? formatAddressFields(claimData, addressFieldsNames.MAILING_ADDRESS)
        : claimData?.mailing_address
          ? `${formatMailingAddress(claimData?.mailing_address)}`
          : 'N/A'
    }`,
    homeAddressMaxWidth,
  )
  pdf.text(homeAddressLines, homeAddressX, yOffset)

  yOffset += 7 * homeAddressLines.length
  pdf.setFontSize(16)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Vehicle Information', labelX, yOffset)

  yOffset += 8
  pdf.setFontSize(12)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Car:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.car_information}`, 25, yOffset)

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('License Plate:', pageWidth / 2, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(
    `${claimData.license_plate_info} (${claimData.license_plate_state || 'N/A'})`,
    pageWidth / 2 + 30,
    yOffset,
  )

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('Parking Start Date:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(
    `${new Date(claimData.parking_start_date).toLocaleDateString()}`,
    50,
    yOffset,
  )

  pdf.setFont('helvetica', 'bold')
  pdf.text('Parking End Date:', pageWidth / 2, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(
    `${new Date(claimData.parking_end_date).toLocaleDateString()}`,
    pageWidth / 2 + 40,
    yOffset,
  )

  yOffset += 17
  pdf.setFontSize(16)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Vehicle Damage Information', 10, yOffset)

  yOffset += 8
  pdf.setFontSize(12)
  pdf.setTextColor('#000')
  pdf.setFont('helvetica', 'bold')
  pdf.text('Type of Damage:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(`${claimData.type_of_damage}`, 50, yOffset)

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('Damage Discovery Date:', 10, yOffset)
  pdf.setFont('helvetica', 'normal')
  pdf.text(
    `${new Date(claimData.damage_discovery_date).toLocaleDateString()}`,
    65,
    yOffset,
  )

  yOffset += 7
  pdf.setFont('helvetica', 'bold')
  pdf.text('Parking Facility Address:', labelX, yOffset)
  pdf.setFont('helvetica', 'normal')
  const parkingAddressX = 65
  const parkingAddressMaxWidth = pageWidth - parkingAddressX - rightMargin
  const parkingAddressLines = pdf.splitTextToSize(
    `${
      claimData?.parking_address_info
        ? formatAddressFields(claimData, addressFieldsNames.PARKING_ADDRESS)
        : claimData?.parking_facility_address
          ? `${claimData.parking_facility_address}`
          : 'N/A'
    }`,
    parkingAddressMaxWidth,
  )
  pdf.text(parkingAddressLines, parkingAddressX, yOffset)

  yOffset += 7 * parkingAddressLines.length

  if (claimData.additional_fields_responses) {
    yOffset += 17
    pdf.setFontSize(16)
    pdf.setTextColor('#000')
    pdf.setFont('helvetica', 'bold')
    pdf.text('Further Details', labelX, yOffset)

    yOffset += 8
    pdf.setFontSize(12)
    pdf.setTextColor('#000')

    const additionalFields = Object.entries(
      claimData.additional_fields_responses,
    )
    const itemsPerRow = 2
    additionalFields.forEach(([key, value], index) => {
      const columnIndex = index % itemsPerRow
      const xOffset = columnIndex === 0 ? 10 : pageWidth / 2

      if (columnIndex === 0 && index > 0) {
        yOffset += 10 // Add space between rows
      }

      pdf.setFont('helvetica', 'bold')
      pdf.text(
        `${formatAndCapitalize(key.replace(/_/g, ' '))}:`,
        xOffset,
        yOffset,
      )
      pdf.setFont('helvetica', 'normal')
      pdf.text(`${value ?? 'N/A'}`, xOffset + 50, yOffset)
    })

    yOffset += 7
  }

  if (claimData.claim_description) {
    yOffset += 10
    pdf.setFontSize(16)
    pdf.setTextColor('#000')
    pdf.setFont('helvetica', 'bold')
    pdf.text('Description of Damage or Loss', labelX, yOffset)

    yOffset += 8
    pdf.setFontSize(12)
    pdf.setTextColor('#000')
    pdf.setFont('helvetica', 'normal')

    const descriptionLines = pdf.splitTextToSize(
      claimData.claim_description,
      pageWidth - 20,
    )
    pdf.text(descriptionLines, labelX, yOffset)

    yOffset += 7 * descriptionLines.length
  }

  if (claimData.claim_amount_receipt) {
    pdf.addPage()
    yOffset = 10

    pdf.setFontSize(18)
    pdf.setTextColor('#000')
    pdf.setFont('helvetica', 'bold')
    pdf.text('Receipt', pageWidth / 2, yOffset, { align: 'center' })

    yOffset += 15

    const receiptElement = document.getElementById('receipt-section')
    if (receiptElement) {
      const receiptCanvas = await html2canvas(receiptElement, {
        useCORS: true,
        allowTaint: false,
        scale: 2,
      })

      const imgData = receiptCanvas.toDataURL('image/png')
      const imgProps = pdf.getImageProperties(imgData)

      const pdfWidth = pageWidth - 20
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

      const maxImgHeight = pageHeight - yOffset - 10
      let imgHeight = pdfHeight
      let imgWidth = pdfWidth

      if (imgHeight > maxImgHeight) {
        imgHeight = maxImgHeight
        imgWidth = (imgProps.width * imgHeight) / imgProps.height
      }

      const xPosition = (pageWidth - imgWidth) / 2

      pdf.addImage(imgData, 'PNG', xPosition, yOffset, imgWidth, imgHeight)
    }
  }

  if (
    claimData.damage_details_images_list &&
    claimData.damage_details_images_list.length > 0
  ) {
    pdf.addPage()
    yOffset = 10

    pdf.setFontSize(18)
    pdf.setTextColor('#000')
    pdf.setFont('helvetica', 'bold')
    pdf.text('Damage Photos', pageWidth / 2, yOffset, { align: 'center' })

    yOffset += 10

    const imagesPerPage = 2
    let imageCount = 0

    const maxImgWidth = pageWidth - 40
    const maxImgHeight = 100
    const spacing = 10

    for (let i = 0; i < claimData.damage_details_images_list.length; i++) {
      const imageUrl = claimData.damage_details_images_list[i]
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.src = imageUrl

      await new Promise((resolve, reject) => {
        img.onload = () => resolve()
        img.onerror = reject
      })

      let imgWidth = img.width
      let imgHeight = img.height

      const widthRatio = maxImgWidth / imgWidth
      const heightRatio = maxImgHeight / imgHeight
      const scale = Math.min(widthRatio, heightRatio)

      imgWidth = imgWidth * scale
      imgHeight = imgHeight * scale

      const xPosition = (pageWidth - imgWidth) / 2

      if (imageCount >= imagesPerPage) {
        pdf.addPage()
        yOffset = 20
        imageCount = 0
      }

      if (yOffset + imgHeight + spacing > pageHeight - 10) {
        pdf.addPage()
        yOffset = 20
        imageCount = 0
      }

      pdf.addImage(img, 'PNG', xPosition, yOffset, imgWidth, imgHeight)

      yOffset += imgHeight + spacing
      imageCount++
    }
  }

  pdf.save(`Claim-${claimData.id}.pdf`)
}
