export const defaultColumnsToDisplay = [
  'partner_id',
  'partner_name',
  'url_suffix',
  'access_key',
  'created_at',
  'actions',
]
export const allColumns = [
  'partner_name',
  'url_suffix',
  'access_key',
  'created_at',
]

export const dateFields = ['created_at']
