import axiosInstance from './axiosConfig'
import { AuthEndPoints } from './api-constants'

export const authenticateUser = async (username, password) => {
  return await axiosInstance.post(AuthEndPoints.login, {
    username,
    password,
  })
}

export const generateQRCode = async token => {
  return await axiosInstance.get(AuthEndPoints.generateQRCode, {
    headers: {
      Authorization: `Token ${token}`,
    },
  })
}

export const verifyOTP = async (otp, token) => {
  return await axiosInstance.post(
    AuthEndPoints.verifyOTP,
    { otp },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  )
}
