import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ListPlus } from '@phosphor-icons/react'

export const AdminSideBar = () => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <div className="flex flex-col relative top-24 min-h-[15rem] h-auto items-center ml-8 p-4 bg-white shadow-md rounded-xl w-48 md:w-60 mb-4 md:mb-0">
      <div className="mt-4 w-full">
        <ul className="gap-y-3 flex flex-col">
          <Link to="/admin/dashboard">
            <li
              className={`flex justify-between items-center p-2 rounded-lg ${pathname === '/admin/dashboard' ? 'bg-blue-100' : ''}`}
            >
              <span className="flex items-center space-x-2">
                <ListPlus className="w-4 h-4 text-black" />
                <span>Form Templates</span>
              </span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  )
}
